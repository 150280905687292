import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { hasMixed, hasNumber, hasSpecial, strengthColor } from "./helper";
import Message from "./Message";
import PasswordStrengthIndicator from "./PasswordStrengthIndicator";
import { useAppDispatch } from "../../../store/store";
import { resetPassword } from "../../../store/features/Auth/auth.api";

const ResetPasswordFrom = () => {
  const [eye, seteye] = useState(true);
  const [password, setPassword] = useState("");
  const [validationError, setValidationError] = useState("");
  const [strength, setStrength] = useState("");

  const dispatch = useAppDispatch();
  const location = useLocation();
  const email = new URLSearchParams(location?.search).get("email");
  const token = new URLSearchParams(location?.search).get("token");

  const onEyeClick = () => {
    seteye(!eye);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const validatePassword = (value) => {
    if (!value) {
      setValidationError(1);
    } else if (value.length < 8) {
      setValidationError(2);
    } else if (!/[0-9]/.test(value)) {
      setValidationError(3);
    } else if (!/[!@#$%^&*()]/.test(value)) {
      setValidationError(4);
    } else {
      setValidationError(5);
    }
  };

  const strengthIndicator = (value) => {
    let strengths = 0;

    if (value.length >= 8) strengths = 1;
    if (hasNumber(value) && value.length >= 8) strengths = 2;
    if (hasSpecial(value) && value.length >= 8 && hasNumber(value)) strengths = 3;
    if (hasMixed(value) && hasSpecial(value) && value.length >= 8 && hasNumber(value)) strengths = 3;
    return strengths;
  };

  useEffect(() => {
    if (password) {
      if (password !== "") {
        let strength = strengthIndicator(password);
        let color = strengthColor(strength);
        setStrength(color);
      } else {
        setStrength("");
      }
    }
  }, [password]);

  const handleFrom = (e) => {
    e.preventDefault();
    if (!password) {
      return;
    }
    console.log(email, token);
    const data = {
      token,
      email,
      password,
      password_confirmation: password,
    };
    dispatch(resetPassword(data));
  };

  return (
    <form onSubmit={handleFrom}>
      <div className="form-group">
        <label className="form-control-label">Password</label>
        <div className="pass-group" id="passwordInput">
          <input
            className="form-control pass-input"
            placeholder="Enter new password"
            type={eye ? "password" : "text"}
            onChange={handlePasswordChange}
          />
          <span onClick={onEyeClick} className={`fa toggle-password feather-eye" ${eye ? "fa-eye" : "fa-eye-slash"}`} />
          <span className="toggle-password feather-eye"></span>
          <span className="pass-checked">
            <i className="feather-check"></i>
          </span>
        </div>
        <PasswordStrengthIndicator strength={strength} />
        <div id="passwordInfo">
          <Message validationError={validationError} />
        </div>
      </div>

      <div className="d-grid">
        <button className="btn btn-primary btn-start" type="submit">
          Reset Password
        </button>
      </div>
    </form>
  );
};

export default ResetPasswordFrom;
