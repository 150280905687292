import React from 'react'
import PropTypes from "prop-types";

const CourseDetailWrapper = ({children}) => {
  return (
    <div className="main-wrapper">
      {children}
    </div>
  )
}

export default CourseDetailWrapper
CourseDetailWrapper.propTypes = {
    children:PropTypes.node.isRequired
}
