import * as yup from "yup";

export const quizDefaultValues = {
  name: "",
  questions: [
    {
      question: "",
      question_type: "",
      options: [{ option: "", is_correct: false }],
    },
  ],
};

export const quizQuestionTypeOptions = [
  { label: "Single Answer", value: "single_answer" },
  { label: "Multiple Answer", value: "multiple_answer" },
];

export const quizValidation = yup.object().shape({
  name: yup.string().required("Name is required"),
  questions: yup.array().of(
    yup.object().shape({
      question: yup.string().required("Question is required"),
      question_type: yup.string().required("Question type is required"),
      options: yup
        .array()
        .of(
          yup.object().shape({
            option: yup.string().required("Option is required"),
            is_correct: yup.boolean().required("This is a required field"),
          }),
        )
        .min(1, "At least one option is required"),

      isCorrect: yup.boolean().test("isCorrect", "At least one option must be correct", function (value) {
        return this.parent.options.some((option) => option.is_correct);
      }),
    }),
  ),
  // .min(1, "At least one question is required"),
});
