import React from "react";
import PropTypes from "prop-types";

const AddNewCourseMainWrapper = ({ children }) => {
  return (
    <section className="page-content course-sec">
      <div className="container">{children}</div>
    </section>
  );
};

export default AddNewCourseMainWrapper;
AddNewCourseMainWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
