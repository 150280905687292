import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { MoreVertical } from "react-feather";

import SendMessageModal from "./SendMessageModal";
import AssignmentApproveAndRejectModal from "./AssignmentApproveAndRejectModal";
import { DangerBadge, PrimaryBadge, SuccessBadge } from "../../../shared/Badge/Badge";
import {
  useApproveOrRejectAssignmentMutation,
  useGetSubmissionsQuery,
} from "../../../services/admin/submission/submission";
import capitalizeFirstLetter from "../../../helpers/capitalizeFirstLetter";

const AssignmentsTable = ({ search }) => {
  const [currentActionMenu, setCurrentActionMenu] = useState(0);
  const [openSendMessageModal, setOpenSendMessageModal] = useState(false);
  const [openAssignmentApproveModal, setOpenAssignmentApproveModal] = useState(false);
  const [openAssignmentRejectModal, setOpenAssignmentRejectModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();

  const { data: submissions, isFetching: is_submission_loading } = useGetSubmissionsQuery({ search });

  const [approveOrReject, { isLoading: is_approving }] = useApproveOrRejectAssignmentMutation();

  const data = submissions?.data?.data;

  const showActionMenu = (index) => {
    setCurrentActionMenu(index);
    setShowMenu(!showMenu);
  };

  const handleSendMessage = () => {
    setOpenSendMessageModal(true);
  };

  const handleAssignmentApprove = () => {
    setOpenAssignmentApproveModal(true);
  };

  const handleAssignmentReject = () => {
    setOpenAssignmentRejectModal(true);
  };

  const approveAssignment = () => {
    approveOrReject({
      id: data[currentActionMenu]?.id,
      body: {
        status: "approved",
      },
    })
      .unwrap()
      .then(() => setOpenAssignmentApproveModal(false));
  };

  const rejectAssignment = () => {
    approveOrReject({
      id: data[currentActionMenu]?.id,
      body: {
        status: "rejected",
      },
    })
      .unwrap()
      .then(() => setOpenAssignmentRejectModal(false));
  };

  function getStatusBadge(status) {
    switch (status) {
      case "approved":
        return <SuccessBadge text={capitalizeFirstLetter(status)} />;
      case "rejected":
        return <DangerBadge text={capitalizeFirstLetter(status)} />;
      default:
        return <PrimaryBadge text={capitalizeFirstLetter(status)} />;
    }
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  function handleFileDownload() {
    const currentAssignment = data[currentActionMenu];

    fetch(currentAssignment?.file)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = currentAssignment?.file_name;
        document.body.appendChild(link);
        link.click();
        setShowMenu(false);
        document.body.removeChild(link);
      });
  }

  return (
    <>
      <div className="settings-widget" ref={menuRef}>
        <div className="settings-inner-blk p-0 position-relative">
          <div className="comman-space pb-0">
            <div className="settings-referral-blk table-responsive ">
              <table className="table table-nowrap">
                <thead>
                  <tr>
                    <th>FILE NAME</th>
                    <th>STATUS</th>
                    <th>SUBMISSION DATE</th>
                    <th>STUDENT NAME</th>
                    <th>LESSON NAME</th>
                    <th>CHAPTER NAME</th>
                    <th>COURSE NAME</th>
                    <th>EMAIL</th>
                    <th style={{ color: "transparent", userSelect: "none" }}>hidden</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.file_name}</td>
                        <td className="px-2">
                          <div>{getStatusBadge(item?.status)}</div>
                        </td>
                        <td className="px-2">{item?.submitted_at}</td>
                        <td className="px-2">{item?.user_name}</td>
                        <td className="px-2">{item?.lesson_name}</td>
                        <td className="px-2">{item?.chapter_name}</td>
                        <td className="px-2">{item?.course_name}</td>
                        <td className="px-2">{item?.user_email}</td>
                        <td style={{ color: "transparent", userSelect: "none" }}>hidden</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="wrapper__action px-2">
              {data?.map((item, index) => (
                <div className="action__dropdown__wrapper" key={item?.id}>
                  <span onClick={() => showActionMenu(index)}>
                    <MoreVertical />
                  </span>
                  {currentActionMenu === index && showMenu && (
                    <div className={`action__drondown `}>
                      <button onClick={handleFileDownload}>Download File</button>
                      <button onClick={handleSendMessage}>Send Message</button>
                      <button onClick={handleAssignmentApprove}>Approve Submission</button>
                      <button onClick={handleAssignmentReject}>Reject Submission</button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {openSendMessageModal && (
        <SendMessageModal
          modalOpen={openSendMessageModal}
          setModalOpen={setOpenSendMessageModal}
          assignment={data[currentActionMenu]}
        />
      )}
      {openAssignmentApproveModal && (
        <AssignmentApproveAndRejectModal
          showIcon={true}
          modalOpen={openAssignmentApproveModal}
          setModalOpen={setOpenAssignmentApproveModal}
          title="Approve Assignment"
          description="Are you sure you want to approve the assignment. Once the assignment is approved, the lesson will be marked complete."
          btnText="Aprove"
          onClick={approveAssignment}
          loading={is_approving}
        />
      )}
      {openAssignmentRejectModal && (
        <AssignmentApproveAndRejectModal
          modalOpen={openAssignmentRejectModal}
          setModalOpen={setOpenAssignmentRejectModal}
          title="Reject Assignment"
          description="Are you sure you want to reject the assignment. This action cannot be undone."
          btnText="Reject"
          onClick={rejectAssignment}
          loading={is_approving}
        />
      )}
    </>
  );
};

export default AssignmentsTable;

AssignmentsTable.propTypes = {
  search: PropTypes.string.isRequired,
};
