import React, { useState } from "react";
import { Trash2 } from "react-feather";
import PropTypes from "prop-types";

import "./index.css";
import Slide from "./Slide";

const PresentationSlides = ({ data, setNewData }) => {
  const [active, setActive] = useState(false);

  return (
    <>
      <div className="slides__section">
        <div className="slides__wrapper ">
          <div className="header__section d-flex justify-content-between align-items-center ">
            <span onClick={() => setNewData((prev) => prev.filter((item) => item?.page_number !== data?.page_number))}   style={{ marginBottom: "6px" }}>
              <Trash2 size={20} />
            </span>
          </div>
          <div className="slides__container d-flex flex-column" style={{ gap: "15px" }} >
            <Slide active={active} setActive={setActive} data={data} setData={setNewData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PresentationSlides;

PresentationSlides.propTypes = {
  data: PropTypes.object.isRequired,
  setNewData: PropTypes.func.isRequired,
};
