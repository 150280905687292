/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import Modal from "../../../../../../shared/modal/Modal";
import TextField from "../../../../../../shared/inputs/TextField/TextField";
import ModalFooter from "../../../../../../shared/modal/ModalFooter";
import { PrimaryButton } from "../../../../../../shared/button/Button";
import { addLessonApi } from "../../../../../../store/features/admin/lesson/lesson.api";
import { quizDefaultValues, quizValidation } from "./data";
import QuestionCard from "./QuestionCard";

import "./index.css";

const AddQizLessonModal = ({ lesson, setLesson, chapter, modalOpen, setModalOpen }) => {
  const [editMode, setMode] = useState(false);

  const dispatch = useDispatch();

  const mainForm = useForm({
    defaultValues: quizDefaultValues,
    resolver: yupResolver(quizValidation),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = mainForm;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  useEffect(() => {
    if (lesson) {
      setMode(!editMode);
      reset({
        ...lesson?.quiz,
        id: lesson?.id,
      });
    }
    return () => setLesson(null);
  }, [lesson]);

  const clear = () => {
    setModalOpen(false);
  };

  const submitForm = (values) => {
    const data = {
      chapter_id: chapter?.id,
      type: "quiz",
      ...values,
    };

    dispatch(addLessonApi({ formData: data }));
    reset();
    setModalOpen(false);
  };

  const handleAddQuestion = () =>
    append({ question: "", question_type: "", options: [{ option: "", is_correct: false }] });

  const handleRemoveQuestion = () => remove(fields.length - 1);

  return (
    <Modal
      title={(editMode ? "Edit" : "New") + " Lesson"}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      onClear={clear}
    >
      <FormProvider {...mainForm}>
        <form className="mt-3" onSubmit={handleSubmit(submitForm)}>
          <TextField name="name" label="Title" register={register} error={errors.name} />

          {fields.map((data, index) => {
            return <QuestionCard key={index} index={index} />;
          })}

          <ModalFooter>
            <div className=" curriculum-head pb-0">
              <button type="button" className="btn mx-3" onClick={handleAddQuestion}>
                Add Question
              </button>
              <button type="button" className="btn mx-3" onClick={handleRemoveQuestion}>
                Remove Question
              </button>
              <PrimaryButton text="Submit" type="submit" />
            </div>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddQizLessonModal;
