import React from 'react'
import PropTypes from "prop-types";

const CoursesTypography = ({text,subtext}) => {
  return (
    <div className="sell-course-head comman-space">
    <h3>{text}</h3>
    <p>{subtext}</p>
  </div>
  )
}

export default CoursesTypography

CoursesTypography.propTypes={
    text:PropTypes.string.isRequired,
    subtext:PropTypes.string.isRequired
}

