import React, { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { loginUser } from "../../../store/features/Auth/auth.api";
import ROUTES from "../../../helpers/constants/routes";
import { ROLES } from "../../../helpers/constants/roles";

const LoginForm = () => {
  const authState = useAppSelector((state) => state.authSlice);
  const navigate = useNavigate();

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [emailInput, setEmailInput] = useState("");

  const dispatch = useAppDispatch();

  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handleFrom = (e) => {
    e.preventDefault();
    if (!emailInput || !passwordInput) {
      return;
    }
    const data = {
      email: emailInput,
      password: passwordInput,
    };
    dispatch(loginUser(data));
  };

  const redirectUser = (role) => {
    switch (role) {
      case ROLES.ADMIN: {
        return navigate(ROUTES.DASHBOARD);
      }
      case ROLES.STUDENT: {
        return navigate(ROUTES.STUDENT_COURSES);
      }
      default:
        return;
    }
  };

  useEffect(() => {
    if (authState.is_success.login) {
      redirectUser(authState.user.role);
    }
  }, [authState.is_success.login]);

  return (
    <form action="/reactjs/instructor-dashboard" onSubmit={handleFrom}>
      <div className="form-group">
        <label className="form-control-label">Email</label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter your email address"
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label className="form-control-label">Password</label>
        <div className="pass-group">
          <input
            type={passwordType}
            onChange={handlePasswordChange}
            value={passwordInput}
            name="password"
            className="form-control"
            placeholder="Password"
          />
          <span className="toggle-password feather-eye" onClick={togglePassword}>
            {passwordType === "password" ? <FeatherIcon icon="eye" /> : <FeatherIcon icon="eye-off" />}
          </span>
        </div>
      </div>
      <div className="forgot">
        <span>
          <Link className="forgot-link" to="/forgot-password">
            Forgot Password ?
          </Link>
        </span>
      </div>
      <div className="remember-me">
        <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
          {" "}
          Remember me
          <input type="checkbox" name="radio" />
          <span className="checkmark" />
        </label>
      </div>
      <div className="d-grid">
        <button className="btn btn-start" type="submit">
          Sign In
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
