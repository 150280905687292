import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import localDb from "../helpers/utils/localDb/localDb";
import { BASE_URL } from "./urlConfig";

export const customBaseQuery = fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders });

// function prepareHeaders(headers, { getState }) {
async function prepareHeaders(headers) {
  const token = await getToken();
  // Your header setup logic here
  // const state = getState();
  //   const token = state.auth.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
}

async function getToken() {
  return new Promise((resolve) => {
    const token = localDb.fetch("access_token");
    resolve(token);
  });
}
