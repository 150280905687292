import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";

export const customStyles = {
  option: (base, { isFocused }) => {
    return {
      ...base,
      backgroundColor: isFocused ? "#FFDEDA" : "white",
      color: "#000000",
    };
  },
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
    transition: "300ms",
  }),
};

export const validationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_VALIDATION("Name")),
  instructor: yup.object().required(REQUIRED_VALIDATION("Instructor")),
});
export const formOptions = {
  resolver: yupResolver(validationSchema),
  mode: "onTouched",
  reValidateMode: "onChange",
};
