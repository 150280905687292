import React, { useRef } from "react";
import PropTypes from "prop-types";

import InputLabel from "../inputs/inputLabel/InputLabel";
import { userAvatar } from "../../helpers/utils/image/imagePath";
import "./index.css";
import { DangerTextButton, PrimaryTextButton } from "../button/Button";
import { getBase64, getFileExtension } from "../../helpers/utils/File/fileHelper";

const UploadAvatar = ({ label, file, setFileInfo }) => {
  const inputRef = useRef();
  const openfile = () => {
    inputRef?.current?.click();
  };
  const handleFile = async (e) => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    const extension = getFileExtension(e.target.files[0].name);
    const base64Image = await getBase64(e.target.files[0]);
    setFileInfo({ imageUrl, extension, base64Image });
  };

  const clearFile = () => {
    setFileInfo({});
  };
  return (
    <div>
      <InputLabel htmlFor={label} label={label} />
      <input type="file" accept="image/*" style={{ display: "none" }} ref={inputRef} onChange={handleFile} />
      <div className="sc_upload_avatar_section">
        <img src={file.imageUrl ? file.imageUrl : userAvatar} className="image" />
        <div className="btn_section">
          <PrimaryTextButton text="Upload new avatar" onClick={openfile} />
          <div className="mt-2" />
          <DangerTextButton text="Remove" onClick={clearFile} />
        </div>
      </div>
    </div>
  );
};

export default UploadAvatar;

UploadAvatar.propTypes = {
  label: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  setFileInfo: PropTypes.func.isRequired,
};
