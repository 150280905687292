import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "../../../settings/customBaseQuery";
import validateParams from "../../../helpers/utils/validateParams";

const SUBMISSION_ENDPOINT = "/assignments/submissions/";
const SUBMISSION_TAG = "Submission";

// Define a service using a base URL and expected endpoints
export const submissionApi = createApi({
  reducerPath: "submissionApi",
  baseQuery: customBaseQuery,
  tagTypes: [SUBMISSION_TAG],
  endpoints: (builder) => ({
    getSubmissions: builder.query({
      query: (args) => {
        if (args) {
          const params = validateParams(args);
          return {
            url: SUBMISSION_ENDPOINT,
            params,
          };
        }
        return {
          url: SUBMISSION_ENDPOINT,
        };
      },
      providesTags: [SUBMISSION_TAG],
    }),

    approveOrRejectAssignment: builder.mutation({
      query: ({ id, body }) => ({
        url: SUBMISSION_ENDPOINT + id,
        method: "PUT",
        body,
      }),
      invalidatesTags: [SUBMISSION_TAG],
    }),

    submitFeedback: builder.mutation({
      query: ({ id, body }) => ({
        url: SUBMISSION_ENDPOINT + id,
        method: "PUT",
        body,
      }),
      invalidatesTags: [SUBMISSION_TAG],
    }),
  }),
});

export const { useGetSubmissionsQuery, useApproveOrRejectAssignmentMutation, useSubmitFeedbackMutation } =
  submissionApi;
