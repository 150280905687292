import React from "react";
import PropTypes from "prop-types";

import { Header } from "./Header";
import Sidebar from "./Sidebar";

export const AdminLayout = ({ children, hideSideBar, hideLayout }) => {
  if (hideLayout) {
    return children;
  }
  return (
    <div className="main-wrapper">
      <Header activeMenu={"Dashboard"} />
      {hideSideBar ? (
        children
      ) : (
        <div className="page-content instructor-page-content">
          <div className="container">
            <div className="row">
              <Sidebar activeMenu={"Dashboard"} />
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
  hideSideBar: PropTypes.bool,
  hideLayout: PropTypes.bool,
};
