import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import ROUTES from "../helpers/constants/routes";
import Login from "../pages/auth/login";
import SingnUp from "../pages/auth/signup";
import ForgotPassword from "../pages/auth/forgotPassword";
import ResetPassword from "../pages/auth/resetPassword/ResetPassword";
import CheckAuth from "./middleware/CheckAuth";
import localDb from "../helpers/utils/localDb/localDb";
import { ROLES } from "../helpers/constants/roles";

const AuthRoute = () => {
  return (
    <Routes>
      <Route element={<CheckAuth />}>
        <Route exact path={ROUTES.LOGIN} element={<Login />} />
        <Route exact path={ROUTES.SIGNUP} element={<SingnUp />} />
        <Route exact path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route exact path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      </Route>
    </Routes>
  );
};

export default AuthRoute;
