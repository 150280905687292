import { combineReducers } from "redux";
import { pokemonApi } from "../services/sample";
import authSlice from "./features/Auth/auth.slice";
import lessonSlice from "./features/admin/lesson/lesson.slice";
import studentSlice from "./features/Student/student.slice";
import { instructorApi } from "../services/admin/instructor/instructor";
import { enrolledStudentApi } from "../services/admin/student/student";
import { addCourseBasicInfoApi } from "../services/admin/addCourse/basicInfo/course";
import { chapterApi } from "../services/admin/addCourse/chapter/chapter";
import { coursesApi } from "../services/admin/course/courses";
import { learningPathApi } from "../services/admin/learningPath/learningPath";
import { learningPathDetailApi } from "../services/admin/learningPathDetail/learningPathDetail";
import { siteSettingApi } from "../services/admin/siteSetting/siteSetting";
import { submissionApi } from "../services/admin/submission/submission";

const RootReducer = combineReducers({
  authSlice,
  lessonSlice,
  [submissionApi.reducerPath]: submissionApi.reducer,
  [pokemonApi.reducerPath]: pokemonApi.reducer,
  [instructorApi.reducerPath]: instructorApi.reducer,
  [enrolledStudentApi.reducerPath]: enrolledStudentApi.reducer,
  [addCourseBasicInfoApi.reducerPath]: addCourseBasicInfoApi.reducer,
  [chapterApi.reducerPath]: chapterApi.reducer,
  [coursesApi.reducerPath]: coursesApi.reducer,
  [learningPathApi.reducerPath]: learningPathApi.reducer,
  [learningPathDetailApi.reducerPath]: learningPathDetailApi.reducer,
  [siteSettingApi.reducerPath]: siteSettingApi.reducer,
  studentSlice,
});

export default RootReducer;
