import React from "react";
import AuthLayout from "../../../layout/auth/AuthLayout";
import ResetPasswordFrom from "../../../components/auth/resetPassword/ResetPasswordFrom";

const ResetPassword = () => {
  const desc =
    "Enter your email address, follow our simple instructions, and regain control over your educational journey.";
  return (
    <AuthLayout description={desc}>
      <h1>Reset Password ?</h1>
      <ResetPasswordFrom />
    </AuthLayout>
  );
};

export default ResetPassword;
