import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PropTypes from "prop-types";
import { DeleteTextButton } from "../../../../../../shared/button/Button";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ShowPdf = ({ url, handleRemove }) => {
  return (
    <div className="sc_show_selected_file_section mb-2">
      <Document file={url}>
        <Page pageNumber={1} width={150} height={150} />
      </Document>

      <div className="sc_delete_button_wrapper">
        <DeleteTextButton text="Remove File" onClick={handleRemove} />
      </div>
    </div>
  );
};

export default ShowPdf;

ShowPdf.propTypes = {
  url: PropTypes.string.isRequired,
  handleRemove: PropTypes.func.isRequired,
};
