import React, { useState } from "react";
import { useDebounce } from "use-debounce";
import InstructorMainWrapper from "../instructors/Wrapper";
import StudentHeader from "../../../components/admin/student/StudentHeader";
import StudentTable from "../../../components/admin/student/StudentTable";
import { useGetEnrolledStudentsQuery } from "../../../services/admin/student/student";
import Pagination from "../../../shared/pagination/Pagination";

const Students = () => {
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [debouncedSerach] = useDebounce(searchText, 500);
  const [debouncedPage] = useDebounce(page, 400);

  const { isFetching, isSuccess, data } = useGetEnrolledStudentsQuery({ debouncedSerach, debouncedPage });

  return (
    <InstructorMainWrapper>
      <StudentHeader searchText={searchText} setSearchText={setSearchText} />
      <StudentTable isSuccess={isSuccess} data={data?.data?.data} />
      <Pagination data={data?.data?.pagination} setPage={setPage} />
    </InstructorMainWrapper>
  );
};

export default Students;
