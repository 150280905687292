import React from "react";
import CoursesTypography from "../../../shared/Typography/CoursesTypography";
import SudentCoursesMainWrapper from "../../../components/students/wrapper/StudentCoursesMainWrapper";
import LearningPathsComp from "../../../components/students/LearningPaths";

const LearningPaths = () => {
  return (
    <SudentCoursesMainWrapper>
      <CoursesTypography
        text="Learning Path"
        subtext="Achieve your learning goals with our step-by-step guided learning paths"
      />
      <LearningPathsComp />
    </SudentCoursesMainWrapper>
  );
};

export default LearningPaths;
