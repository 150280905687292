import React from 'react'
import ReactModal from 'react-modal';
import ModalFooter from '../../../shared/modal/ModalFooter';
import { DangerButton, PrimaryButton } from '../../../shared/button/Button';
import PropTypes from "prop-types";
import { doneIcon ,danger} from "../../../helpers/utils/image/imagePath";
import { X } from "react-feather";

const AssignmentApproveAndRejectModal = ({ title, description,showIcon, modalOpen,btnText, setModalOpen, onClick, loading }) => {
    function afterOpenModal() {}

    function closeModal() {
      setModalOpen(false);
    }
  return (
    <ReactModal
    isOpen={modalOpen}
    onAfterOpen={afterOpenModal}
    onRequestClose={closeModal}
    ariaHideApp={false}
    className="sc_modal_content sc_delete_modal_content"
    overlayClassName="sc_overlay"
  >
    <div className="sc_model__content">
      <X size={20} className="sc_modal_close_icon" onClick={() => setModalOpen(false)} />
      <div className="sc_delete_modal_title_section">
        {
          showIcon ? ( <img src={doneIcon} alt="delete icon" className="sc_modal_danger_icon" />)
           : (  <img src={danger} alt="delete icon" className="sc_modal_danger_icon" />)
        }
       
        <span className="sc_modal_title">{title}</span>{" "}
      </div>
      <span className="sc_modal_delete_description">{description}</span>
    </div>
    <div className="sc_delete_modal_footer">
      <ModalFooter>
        <div className="mx-3">
          <PrimaryButton text={btnText} onClick={onClick} loading={loading} />
        </div>
        <DangerButton text="Cancel" onClick={() => setModalOpen(false)} />
      </ModalFooter>
    </div>
  </ReactModal>
  )
}

export default AssignmentApproveAndRejectModal
AssignmentApproveAndRejectModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    setModalOpen: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    btnText:PropTypes.string.isRequired,
    showIcon:PropTypes.bool
  };
