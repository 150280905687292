/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from "react";
import { Trash2 } from "react-feather";
import { Page } from "react-pdf";
import { toast } from "react-toastify";

import FileSavingProgress from "../FileUploadProgresses/FileSavingProgress";
import ScModal from "../../../../../../../shared/modal/CustomModal/ScModal";
import ScModalHeader from "../../../../../../../shared/modal/CustomModal/ScModalHeader";
import ScModalBody from "../../../../../../../shared/modal/CustomModal/ScModalBody";
import { PrimaryButton } from "../../../../../../../shared/button/Button";
import { formatTime } from "../../../../../../../helpers/utils/formatTime";
import { useGetAudioUrlMutation } from "../../../../../../../services/admin/student/student";

import "./audioRecordModal.css";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

const AudioRecordModal = ({ modalOpen, setModalOpen, page, setData }) => {
  const [fileSavingProgress, setFileSavingProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const audioRef = useRef(new Audio());
  const [timer, setTimer] = useState(0);
  const [loading, setLoading] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);

  const [generateAudioUrl] = useGetAudioUrlMutation();

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isRecording]);

  const clearFile = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
    }
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    setAudioBlob(null);
    setTimer(0);
    setCurrentTime(0);
    setAudioDuration(0);
    setFileSavingProgress(0);
    setMediaRecorder(null);
    setIsRecording(false);
  };

  const requestMicPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      return true;
    } catch (error) {
      return false;
    }
  };

  const checkMicPermission = async () => {
    try {
      // Check microphone permission
      const result = await navigator.permissions.query({ name: "microphone" });
      return result.state === "granted";
    } catch (error) {
      // Handle any errors
      toast.error("Error checking microphone permission");
      return false;
    }
  };

  const handleRecordAudio = async () => {
    //clear save record if playing
    if (audioRef.current.play) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setTimer(0);
      setCurrentTime(0);
      setAudioBlob(null);
    }

    const hasMicPermission = await checkMicPermission();

    if (!hasMicPermission) {
      // If permission is not granted, ask the user
      const userGrantedPermission = await requestMicPermission();

      if (!userGrantedPermission) {
        toast.error("Microphone permission not granted");
        return;
      }
    }

    // Start recording audio
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = new MediaRecorder(stream);
      const chunks = [];

      recorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
        }
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/wav" });
        setAudioBlob(blob);
      };

      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
    });
  };

  const handleStopAudio = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
      setIsRecording(false);
      setAudioDuration(timer);
    }
  };

  const handleSaveAudio = async () => {
    // Save the recorded audio or handle saving logic
    if (audioBlob) {
      const formdata = new FormData();
      formdata.append("audio", audioBlob);
      setLoading(true);
      await generateAudioUrl(formdata)
        .unwrap()
        .then((res) => {
          setData((prev) =>
            prev.map((item) => {
              if (item?.page_number === page?.page_number) {
                return {
                  ...item,
                  audio_url: res?.data,
                  audio_duration: audioDuration,
                };
              }
              return item;
            }),
          );
          setLoading(false);
          setFileSavingProgress(100);
          clearFile();
          setModalOpen(false);
        })
        .catch((err) => setLoading(false));
    }
  };

  const handlePlay = () => {
    if (audioBlob) {
      if (audioRef.current.paused) {
        if (currentTime > 0) {
          audioRef.current.currentTime = currentTime;
        }

        audioRef.current.src = URL.createObjectURL(audioBlob);
        audioRef.current.play();

        audioRef.current.addEventListener("timeupdate", () => {
          setTimer(Math.floor(audioRef.current.currentTime));
        });
      } else {
        setCurrentTime(audioRef.current.currentTime);
        audioRef.current.pause();
      }
    }
  };

  return (
    <>
      <ScModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <ScModalHeader title="Record audio" />
        <ScModalBody>
          <form className="audio__record__container">
            <div className="audio__records__actions">
              <div className="left__actions">
                <PrimaryButton
                  type={"button"}
                  text={isRecording ? "Recording..." : "Record"}
                  onClick={isRecording ? handleStopAudio : handleRecordAudio}
                  disabled={isRecording}
                />
                <PrimaryButton type={"button"} text={"Stop"} onClick={handleStopAudio} disabled={!isRecording} />
                {audioBlob && (
                  <button type="button" className={`audio__action__btn play__btn`} onClick={handlePlay}>
                    {audioRef.current.paused ? "Play" : "Pause"}
                  </button>
                )}

                <div className="audio__record__timer">
                  <span>{formatTime(timer)}</span>
                </div>
              </div>
              <div className="right__actions">
                <div>
                  <span onClick={clearFile}>
                    <Trash2 />
                    Delete
                  </span>
                  <PrimaryButton type="button" loading={loading} text={"Save"} onClick={handleSaveAudio} />
                </div>
              </div>
            </div>
            {fileSavingProgress > 0 && <FileSavingProgress progress={fileSavingProgress} clearFile={clearFile} />}

            <div className="view__pdf__wrapper">
              <div className="view__pdf__container">
                <div style={{ height: "520px", overflowY: "scroll" }}>
                  <Page pageNumber={page?.page_number} />
                  <p className="d-flex align-items-center text-center justify-content-center my-2">
                    Page {page?.page_number}
                  </p>
                </div>
              </div>
            </div>
          </form>
        </ScModalBody>
      </ScModal>
    </>
  );
};

export default AudioRecordModal;
