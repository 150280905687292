import React from "react";
import PropTypes from "prop-types";

const InputLabel = ({ htmlFor, label }) => {
  return (
    <label htmlFor={htmlFor} className="add-course-label">
      {label}
    </label>
  );
};

export default InputLabel;

InputLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
