/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Search } from "react-feather";
import Select from "react-select";
import { options, style } from "./helpers";

const Filter = ({ searchText, setSearchText, setFilterStatus }) => {
  return (
    <div className="instruct-search-blk">
      <div className="show-filter choose-search-blk">
        <form action="#">
          <div className="row gx-2 align-items-center">
            <div className="col-md-6 col-item">
              <div className=" search-group">
                <Search
                  size={16}
                  style={{
                    position: "absolute",
                    left: "7px",
                    color: "#F66962",
                  }}
                />
                <input
                  type="text"
                  className="form-control"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search our courses"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-item">
              <div className="form-group select-form mb-0">
                <Select
                  className=" select"
                  name="sellist1"
                  options={options}
                  defaultValue={options[0]}
                  placeholder="Choose"
                  onChange={(e) => setFilterStatus(e.value)}
                  styles={style}
                ></Select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filter;
