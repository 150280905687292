import React from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import { X } from "react-feather";

const Modal = ({ children, title, modalOpen, setModalOpen, onClear }) => {
  function afterOpenModal() {}

  const haldeClearFrom = () => {
    onClear();
    setModalOpen(false);
  };
  return (
    <ReactModal
      isOpen={modalOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={haldeClearFrom}
      ariaHideApp={false}
      className="sc_modal_content sc_hide_scrollbar"
      overlayClassName="sc_overlay"
    >
      <div className="sc_model__content">
        <X size={20} className="sc_modal_close_icon" onClick={haldeClearFrom} />
        <span className="sc_modal_title">{title}</span>
        <div className="sc_modal_real_content_section">{children}</div>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

export default Modal;
