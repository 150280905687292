import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import TextField from "../../../shared/inputs/TextField/TextField";
import { updateUserProfile } from "../../../store/features/Auth/auth.api";

const ProfileForm = () => {
  const { user, loading } = useSelector((state) => state.authSlice);

  const dipatch = useDispatch();

  const { register, handleSubmit, formState, reset } = useForm();

  const submitData = (values) => {
    dipatch(updateUserProfile(values));
  };

  useEffect(() => {
    const defaultValues = {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
    };
    reset(defaultValues);
  }, [user]);

  return (
    <form onSubmit={handleSubmit(submitData)}>
      <div className="row">
        <div className="col-lg-12">
          <TextField
            name="first_name"
            type="text"
            placeholder="Enter your first Name"
            label="First Name"
            register={register}
          />
        </div>

        <div className="col-lg-12">
          <TextField
            name="last_name"
            type="text"
            placeholder="Enter your last Name"
            label=" Last Name"
            register={register}
          />
        </div>

        <div className="col-lg-12">
          <TextField name="email" type="email" placeholder="Enter your Email" label=" Email" register={register} />
        </div>

        <div className="update-profile d-flex justify-content-end">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            Update Profile
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProfileForm;
