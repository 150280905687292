import React from "react";

import PropTypes from "prop-types";
import { Icon1, Icon2, courseImage } from "../../helpers/utils/image/imagePath";


const LearningPathCourseCard = ({ id, imageUrl, title, courseCount, courseDuration, courseDescription, onClick }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12" onClick={(e) => onClick(id)}>

          <div className="card instructor-card border-0">
            <div className="card-body p-0">
              <div className="instructor-grid ps-0 pt-0 pe-0 border-0 border-bottom flex-nowrap align-items-start">
                <div className="product-img">
                  {/* <Link to="course-details"> */}

                  <img
                    src={imageUrl ? imageUrl : courseImage}
                    className="img-fluid sc_learnig_path_cover_image"
                    alt=""
                  />

                  {/* </Link> */}
                </div>
                <div className="instructor-product-content">
                  <div className="head-course-title">
                    <h3 className="title">
                      <div className="course__title">{title}</div>
                    </h3>
                  </div>
                  <div className="course-info d-flex align-items-center border-bottom-0 pb-0">
                    <div className="rating-img d-flex align-items-center">
                      <img src={Icon1} alt="" />
                      <p>{courseCount}+ Lesson</p>
                    </div>
                    <div className="course-view d-flex align-items-center">
                      <img src={Icon2} alt="" />
                      <p>{courseDuration}</p>
                    </div>
                  </div>
                  <div className="rating mb-0">
                    <p className="three_lines">{courseDescription}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningPathCourseCard;

LearningPathCourseCard.propTypes = {

  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,

  title: PropTypes.string.isRequired,
  courseCount: PropTypes.number.isRequired,
  courseDuration: PropTypes.string.isRequired,
  courseDescription: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
