import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { getBase64 } from "../../helpers/utils/File/fileHelper";
import { userAvatar } from "../../helpers/utils/image/imagePath";
import { updateUserProfile } from "../../store/features/Auth/auth.api";

const AvatarSection = () => {
  const { user, loading } = useSelector((state) => state.authSlice);

  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const inputRef = useRef();
  const openfile = () => {
    inputRef?.current?.click();
  };
  const handleFile = async (e) => {
    const base64Image = await getBase64(e.target.files[0]);
    setFile(base64Image);
  };

  const handleSubmit = () => {
    dispatch(updateUserProfile({ avatar_url: file }));
  };

  const clearFile = () => {
    const data = {
      avatar_url: null,
    };
    dispatch(updateUserProfile(data));
    setFile(null);
  };

  return (
    <div className="course-group mb-0 d-flex">
      <div className="course-group-img d-flex align-items-center">
        <div className="profile__picture__img__wrapper">
          <img src={file ? file : user?.avatar_url || userAvatar} alt="user" className="img-fluid" />
        </div>

        <div className="course-name">
          <h4>
            <div className="your__avatar">Your avatar</div>
          </h4>
          <p>PNG or JPG no bigger than 800px wide and tall.</p>
        </div>
      </div>
      <div className="profile-share d-flex align-items-center justify-content-center">
        {!file ? (
          <button type="button" className="btn btn-success" onClick={openfile}>
            Update
            <input
              type="file"
              accept="image/*"
              id="avatar"
              style={{ display: "none" }}
              ref={inputRef}
              onChange={handleFile}
              disabled={loading}
            />
          </button>
        ) : (
          <button type="submit" className="btn btn-success" onClick={handleSubmit} disabled={loading}>
            Submit
          </button>
        )}
        <button type="button" className="btn btn-danger" onClick={clearFile} disabled={loading}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default AvatarSection;

AvatarSection.propTypes = {
  file: PropTypes.object.isRequired,
  setFileInfo: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
};
