export class LocalDb {
  save(key, dataToSave) {
    try {
      localStorage.setItem(key, JSON.stringify(dataToSave));
    } catch (error) {
      console.log("Error while saving to Local storage.", error);
    }
  }

  fetch(key) {
    // if (itemGot !== null) {
    //   return JSON.parse(itemGot);
    // }
    // return null;

    try {
      const itemGot = localStorage.getItem(key);
      if (itemGot !== null) {
        return JSON.parse(itemGot);
      }
    } catch (error) {
      return null;
    }
  }

  remove(key) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.log("Error while removing data from Local storage.", error);
    }
  }
  clear() {
    try {
      localStorage.clear();
    } catch (error) {
      console.log("Error while clearing Local storage.", error);
    }
  }
}
export default new LocalDb();
