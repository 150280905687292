import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import LearningPathCourseCard from "../../../shared/Card/LearningPathCourseCard";
import { learningPathCoursesData } from "../../students/LearningPathCourses/helper";
import Empty from "../../../shared/Empty/Empty";
import SearchAndButton from "./SearchAndButton";
import Wrapper from "./Wrapper";
import From from "./From";
import { useGetLearningPathQuery } from "../../../services/admin/learningPath/learningPath";
import Pagination from "../../../shared/pagination/Pagination";

const LearningPathListAndForm = () => {
  const [page, setPage] = useState(1);
  const timestampRef = useRef(Date.now()).current;
  const [searchText, setSearchText] = useState("");
  const [debouncedSerach] = useDebounce(searchText, 500);
  const [debouncedPage] = useDebounce(page, 400);

  const [openAddModal, setOpenAddModal] = useState(false);
  const { isSuccess: isFetched, data } = useGetLearningPathQuery({
    debouncedSerach,
    debouncedPage,
    sessionId: timestampRef,
  });

  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/admin/learning-path/${id}`);
  };
  return (
    <>
      <Wrapper>
        <SearchAndButton searchText={searchText} setSearchText={setSearchText} setOpenAddModal={setOpenAddModal} />

        <div className="row">
          {isFetched &&
            data?.data?.data?.map((item) => (
              <LearningPathCourseCard
                onClick={handleClick}
                key={item.id}
                id={item.id}
                title={item.name}
                imageUrl={item.image}
                courseCount={item.courses_count}
                courseDuration={item.total_duration}
                courseDescription={item.description}
              />
            ))}
        </div>
        <From openAddModal={openAddModal} setOpenAddModal={setOpenAddModal} />

        {/* Empty Screen*/}
        {isFetched && data?.data?.data?.length == 0 && <Empty emptyText="No Learning paths added." />}
      </Wrapper>
      <Pagination data={data?.data?.pagination} setPage={setPage} />
    </>
  );
};

export default LearningPathListAndForm;
