import React from "react";
import { useSelector } from "react-redux";

import { play01, Timer, Import, Key, Mobile, Chapter } from "../../../../helpers/utils/image/imagePath";

const IncludesSection = () => {
  const { course } = useSelector((state) => state.studentSlice);

  return (
    <div className="sidebar-sec m-0">
      {/* Include */}
      <div className="card include-sec">
        <div className="card-body">
          <div className="cat-title">
            <h4>Includes</h4>
          </div>
          <ul>
            <li>
              <img src={Key} className="me-2" alt="" /> Full lifetime access
            </li>
            <li>
              <img src={Mobile} className="me-2" alt="" /> Access on mobile and TV
            </li>

            <li>
              <img src={Chapter} className="me-2" alt="" /> Chapters: {course?.total_chapters}
            </li>
            <li>
              <img src={play01} className="me-2" alt="" /> Video: {course?.total_duration || 0}
            </li>
          </ul>
        </div>
      </div>
      {/* /Include */}
    </div>
  );
};

export default IncludesSection;
