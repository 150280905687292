import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Trash2 } from "react-feather";

import Wrapper from "./Wrapper";
import { useDebounce } from "use-debounce";
import SearchAndButton from "./SearchAndButton";
import Empty from "../../../shared/Empty/Empty";

import AddFrom from "./AddFrom";
import { useGetCoursesForLearnigPathQuery } from "../../../services/admin/course/courses";
import {
  useDeleteCourseFromLearningPathMutation,
  useGetLearningPathDetailQuery,
  useUpdateOrderOfCourseMutation,
} from "../../../services/admin/learningPathDetail/learningPathDetail";
import { useParams } from "react-router-dom";
import DeleteModal from "../../../shared/deleteModal/DeleteModal";
import { Icon1 } from "../../../helpers/utils/image/imagePath";
import "./index.css";

const LearningPathDetailsList = () => {
  const { id } = useParams();
  const timestampRef = useRef(Date.now()).current;

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [debouncedSerach] = useDebounce(searchText, 500);
  const [allCourses, setAllCourses] = useState([]);
  const [allCoursesPreserved, setAllCoursesPreserved] = useState([]);
  const [pathDetailCourses, setPathDetailCourses] = useState([]);
  const [pathDetailPreservedCourses, setPathDetailPreservedCourses] = useState([]);
  const [activeCourseId, setActiveCourseId] = useState();

  const { isSuccess: isFetched, data } = useGetCoursesForLearnigPathQuery();
  const { isSuccess: isDetailFetched, data: pathDetail } = useGetLearningPathDetailQuery({
    id,
    sessionId: timestampRef,
  });
  const [deleteCourseFromLearningPath, { isLoading: isDeleting, isSuccess: isDeleted }] =
    useDeleteCourseFromLearningPathMutation();

  const [updateOrderOfCourse, { isSuccess: isUpdated }] = useUpdateOrderOfCourseMutation();

  const deleteCourse = () => {
    deleteCourseFromLearningPath({ learningPathId: id, courseId: activeCourseId });
  };

  const handleDeleteModal = (courseId) => {
    setActiveCourseId(courseId);
    setOpenDeleteModal(true);
  };

  const updateCourses = () => {
    const filterArr = data?.data.filter((course) => {
      if (!pathDetail.data.courses_id.includes(course.id)) {
        return course;
      }
    });
    setAllCourses(filterArr);
    setAllCoursesPreserved(filterArr);
  };

  const listenDrag = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const newData = [...pathDetailPreservedCourses];
    const [movedItem] = newData.splice(source.index, 1);
    newData.splice(destination.index, 0, movedItem);

    setPathDetailPreservedCourses(newData);
    setPathDetailCourses(newData);

    const data = {
      sourceIndex: source.index,
      destinationIndex: destination.index,
      course_id: draggableId,
    };

    updateOrderOfCourse({ learningPathId: id, data });
  };

  useEffect(() => {
    if (isFetched && isDetailFetched) {
      updateCourses();
    }
  }, [isFetched, isDetailFetched]);

  useEffect(() => {
    if (isDeleted && pathDetail) {
      setOpenDeleteModal(false);
      updateCourses();
    }
  }, [isDeleted, pathDetail]);

  useEffect(() => {
    if (pathDetail) {
      setPathDetailCourses(pathDetail?.data?.courses);

      setPathDetailPreservedCourses(pathDetail?.data?.courses);
    }
  }, [pathDetail]);

  useEffect(() => {
    if (debouncedSerach) {
      //
      const arr = pathDetailPreservedCourses.filter((data) =>
        data.name.toLowerCase().includes(debouncedSerach.toLowerCase()),
      );
      setPathDetailCourses(arr);
    } else {
      setPathDetailCourses(pathDetailPreservedCourses);
    }
  }, [debouncedSerach]);

  return (
    <Wrapper>
      <SearchAndButton
        searchText={searchText}
        setSearchText={setSearchText}
        setOpenAddModal={setOpenAddModal}
        status={pathDetail?.data?.status}
        name={pathDetail?.data?.name}
      />

      <DragDropContext onDragEnd={listenDrag}>
        <Droppable droppableId="239999">
          {(provided) => (
            <div className="marginTop-20" ref={provided.innerRef}>
              {pathDetailCourses?.map((course, index) => {
                return (
                  <Draggable draggableId={course.id.toString()} key={course.id.toString()} index={index}>
                    {(provided) => {
                      return (
                        <div
                          key={course.id}
                          className="sc_learning_path_course_title"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <div className="title_section">
                            <img src={Icon1} alt="" />
                            <span className="title">{course.name}</span>
                          </div>
                          <Trash2 size={18} className="delete_icon" onClick={() => handleDeleteModal(course.id)} />
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <AddFrom
        openAddModal={openAddModal}
        setOpenAddModal={setOpenAddModal}
        isFetched={isFetched}
        data={allCourses}
        preservedData={allCoursesPreserved}
        setAllCourses={setAllCourses}
      />
      <DeleteModal
        title="Delete Learning Path Course ?"
        description="Are you sure you want to delete course from learning path."
        modalOpen={openDeleteModal}
        setModalOpen={setOpenDeleteModal}
        onClick={deleteCourse}
        loading={isDeleting}
      />
      {/* Empty Screen*/}
      {isFetched && pathDetail?.data?.courses?.length == 0 && <Empty emptyText="No Course added." />}
    </Wrapper>
  );
};

export default LearningPathDetailsList;
