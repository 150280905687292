/* eslint-disable react/prop-types */
import React from "react";
import { EmptyImg } from "../../helpers/utils/image/imagePath";

const Empty = ({ emptyText }) => {
  return (
    <div
      className="container bg-white d-flex flex-column align-items-center justify-content-center "
      style={{ height: "65vh" }}
    >
      <img src={EmptyImg} alt="" />
      <p className="mt-4" style={{ color: "#303030", fontStyle: "normal", fontWeight: 400 }}>
        {emptyText}
      </p>
    </div>
  );
};

export default Empty;
