/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ prevTab1, nextTab2 }) => {
  return (
    <div className="widget-btn">
      <Link className="btn btn-black prev_btn" onClick={prevTab1}>
        Previous
      </Link>
      <Link className="btn btn-info-light next_btn" onClick={nextTab2}>
        Continue
      </Link>
    </div>
  );
};

export default Footer;
