import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import localDb from "../../helpers/utils/localDb/localDb";
import { ROLES } from "../../helpers/constants/roles";

const Student = () => {
  const token = localDb.fetch("access_token");
  const user = localDb.fetch("user");
  if (!token && !user) {
    return <Navigate to="/" />;
  }
  return token && user?.role === ROLES.STUDENT ? <Outlet /> : <Navigate to="/not-found" />;
};

export default Student;
