import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import localDb from "../../helpers/utils/localDb/localDb";
import { ROLES } from "../../helpers/constants/roles";
import ROUTES from "../../helpers/constants/routes";

const CheckAuth = () => {
  const token = localDb.fetch("access_token");
  const user = localDb.fetch("user");

  if (token && user?.role == ROLES.ADMIN) {
    return <Navigate to={ROUTES.DASHBOARD} />;
  }
  if (token && user?.role == ROLES.STUDENT) {
    return <Navigate to={ROUTES.STUDENT_COURSES} />;
  }
  return <Outlet />;
};

export default CheckAuth;
