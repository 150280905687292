import React from "react";
import PropTypes from "prop-types";
import InputLabel from "../inputLabel/InputLabel";
import ErrorText from "../errorText/ErrorText";

const TextArea = ({
  name,
  label,
  type,
  placeholder,
  error,
  onInput,
  required,
  register,
  extraError,
  value,
  ...rest
}) => {
  const errorMessage = error?.message;

  return (
    <div className="form-group">
      <InputLabel htmlFor={name} label={label} />
      <textarea
        id={name}
        name={name}
        type={type || "text"}
        className="form-control sc_textarea"
        placeholder={placeholder}
        onInput={onInput}
        value={value}
        required={required}
        {...(register && register(name))}
        {...rest}
      />
      <ErrorText error={errorMessage} />
      <ErrorText error={extraError} />
    </div>
  );
};

export default TextArea;

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  extraError: PropTypes.string,
  value: PropTypes.string,
  register: PropTypes.any,
  onInput: PropTypes.any,
  required: PropTypes.any,
};
