/* eslint-disable react/prop-types */
import React, { useEffect, useId, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Lesson from "./Lesson";
import { PrimaryButton } from "../../../../shared/button/Button";
import { addLesson } from "../../../../helpers/utils/image/imagePath";
import { useAppSelector } from "../../../../store/store";
import { updateLessonOrder } from "../../../../store/features/admin/lesson/lesson.api";

const Chapter = ({
  data,
  chapterId,
  setOpenLessonModal,
  setActiveChapter,
  setOpenChapternModal,
  openDeleteModal,
  setActiveLesson,
  activeLesson,
  setEditLesson,
  dispatch,
  setOpenAddLessonsModal,
}) => {
  const lessonState = useAppSelector((state) => state.lessonSlice);

  const [allLessons, setAllLessons] = useState([]);
  const customDroppableId = useId();

  const handleEdit = () => {
    setActiveChapter(data);
    setOpenChapternModal(true);
  };
  const openModalForAdd = () => {
    setActiveChapter(data);
    // setOpenLessonModal(true);
    setOpenAddLessonsModal(true);
  };

  useEffect(() => {
    if (data) {
      setAllLessons(data?.lessons);
    }
  }, [data]);

  const listenDrag = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const newData = [...allLessons];
    const [movedItem] = newData.splice(source.index, 1);
    newData.splice(destination.index, 0, movedItem);

    setAllLessons(newData);

    const data = {
      sourceIndex: source.index,
      destinationIndex: destination.index,
      // chapter_id: draggableId,
      chapter_id: chapterId,
    };

    // updateOrderOfCourse({ learningPathId: id, data });
    dispatch(updateLessonOrder({ lessonId: draggableId, data }));
  };

  return (
    <div className="curriculum-grid">
      <div className="curriculum-head">
        <p className="sc_lesson_title">{data.name}</p>
        <button to="#" className="btn" onClick={handleEdit}>
          Edit Chapter
        </button>
      </div>

      <DragDropContext onDragEnd={listenDrag}>
        <Droppable droppableId={customDroppableId}>
          {(provided) => (
            <div className="curriculum-info" ref={provided.innerRef}>
              {allLessons?.map((lesson, index) => {
                return (
                  <Draggable draggableId={lesson.id.toString()} key={lesson.id.toString()} index={index}>
                    {(provided) => {
                      return (
                        <div
                          key={lesson.id}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {" "}
                          <Lesson
                            chapter={data}
                            setActiveChapter={setActiveChapter}
                            openDeleteModal={openDeleteModal}
                            lesson={lesson}
                            setActiveLesson={setActiveLesson}
                            setOpenLessonModal={setOpenLessonModal}
                            setEditLesson={setEditLesson}
                            setOpenAddLessonsModal={setOpenAddLessonsModal}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* empty section */}
      {allLessons.length == 0 && (
        <div className="sc_add_lesson_image_section">
          <img src={addLesson} />
          <span> Add lessons to your course.</span>
        </div>
      )}

      <div className="sc_add_lesson_button_row">
        <PrimaryButton text="Add Lesson" onClick={openModalForAdd} />
      </div>
    </div>
  );
};

export default Chapter;
