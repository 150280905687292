import React from "react";
import SudentCoursesMainWrapper from "../../../components/students/wrapper/StudentCoursesMainWrapper";
import CoursesTypography from "../../../shared/Typography/CoursesTypography";
import LearningPathListAndForm from "../../../components/admin/learningPath";

const AdminLearningPath = () => {
  return (
    <SudentCoursesMainWrapper>
      <CoursesTypography text="Learning Path" subtext="Manage your learning paths from here." />
      <LearningPathListAndForm />
    </SudentCoursesMainWrapper>
  );
};

export default AdminLearningPath;
