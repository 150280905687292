/* eslint-disable react/prop-types */
import React from "react";
import SearchBar from "../../../shared/searchBar/SearchBar";
import { useState } from "react";
import axiosInstance from "../../../settings/axiosInstance";
import { BASE_URL } from "../../../settings/urlConfig";

const StudentHeader = ({ searchText, setSearchText }) => {
  const [loading, setLoading] = useState(false);

  const downloadFile = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`${BASE_URL}/students/export-csv`, {
        responseType: "blob",
      });
      const fileURL = window.URL.createObjectURL(response.data);
      const alink = document.createElement("a");
      alink.href = fileURL;
      const now = new Date();
      alink.download = `users_${now.getFullYear()}_${now.getMonth()}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}.csv`;
      alink.click();
      URL.revokeObjectURL(fileURL);
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      if (!error.response) {
        throw error;
      }
    }
  };
  return (
    <div className="settings-widget">
      <div className="settings-inner-blk p-0">
        <div className="sell-course-head student-meet-head comman-space border-0">
          <div className="tak-head d-flex align-items-center">
            <div>
              <h3>Students</h3>
              <p>Meet people taking your courses.</p>
            </div>
          </div>
          <div className="student-search-form d-flex  justify-content-between  mt-5 marginBotom30">
            <SearchBar searchText={searchText} setSearchText={setSearchText} placeholder="Search" />
            <div className="export-cvv-btn">
              <button className="btn btn-primary" onClick={downloadFile}>
                {!loading ? " Export CSV" : "Downloading..."}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentHeader;
