import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";

import Modal from "../../../shared/modal/Modal";
import CustomTextField from "../../../shared/CKTextEditor/CustomTextField";
import ModalFooter from "../../../shared/modal/ModalFooter";
import { PrimaryButton } from "../../../shared/button/Button";
import { messageValues, validationSchema } from "./data";
import { useSubmitFeedbackMutation } from "../../../services/admin/submission/submission";

const SendMessageModal = ({ modalOpen, setModalOpen, assignment }) => {
  const [submitFeedback, { isLoading: is_submitting }] = useSubmitFeedbackMutation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: messageValues,
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const clear = () => {
    reset();
    setModalOpen(false);
  };

  const submitForm = (values) => {
    submitFeedback({ id: assignment?.id, body: values })
      .unwrap()
      .then(() => clear());
  };

  return (
    <Modal title="Send Feeback" modalOpen={modalOpen} setModalOpen={setModalOpen} onClear={clear}>
      <form className="mt-3" onSubmit={handleSubmit(submitForm)}>
        {/* <TextField name="subject" label="To: joesmith@gmail.com" register={register}  /> */}

        <CustomTextField control={control} name="feedback" label="Content" error={errors.feedback} />
        <ModalFooter>
          <PrimaryButton text="Send" type="submit" loading={is_submitting} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SendMessageModal;
SendMessageModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  assignment: PropTypes.object.isRequired,
};
