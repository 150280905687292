/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Modal from "../../../shared/modal/Modal";
import TextField from "../../../shared/inputs/TextField/TextField";
import ModalFooter from "../../../shared/modal/ModalFooter";
import { PrimaryButton } from "../../../shared/button/Button";
import UploadAvatar from "../../../shared/uploadAvatar/UploadAvatar";
import {
  useCreateInstructorMutation,
  useUpdateInstructorMutation,
} from "../../../services/admin/instructor/instructor";

const AddInstructorFrom = ({ openModal, setOpenModal, isEdit, activeData, setEdit }) => {
  const [createInstructor, { isLoading, isSuccess, error, isError, data }] = useCreateInstructorMutation();
  const [updateInstructor, { isLoading: isUpdating, isSuccess: isUpdated }] = useUpdateInstructorMutation();

  const [file, setFileInfo] = useState({});
  const [name, setName] = useState("");

  const handleInput = (e) => {
    setName(e.target.value);
  };

  const handleForm = (e) => {
    e.preventDefault();

    if (!isEdit) {
      const data = {
        name: name,
        image: file.base64Image,
      };

      createInstructor(data);
    } else {
      const data = {
        id: activeData.id,
        name: name,
        image: file.base64Image,
      };

      updateInstructor(data);
    }
  };

  const clearFrom = () => {
    setName("");
    setFileInfo({});
    setEdit(false);
  };

  useEffect(() => {
    if (isSuccess || isUpdated) {
      clearFrom();
      setOpenModal(false);
    }
  }, [isSuccess, isUpdated]);

  useEffect(() => {
    if (isEdit) {
      activeData;
      setName(activeData.name);
      setFileInfo({ imageUrl: activeData.image, base64Image: activeData.image });
    }
  }, [isEdit]);

  return (
    <Modal
      title={isEdit ? "Edit Instructor" : "Add Instructor"}
      modalOpen={openModal}
      setModalOpen={setOpenModal}
      onClear={clearFrom}
    >
      <form className="mt-3" onSubmit={handleForm}>
        <TextField name="name" label="Instructor Name" required onInput={handleInput} value={name} />
        <UploadAvatar file={file} setFileInfo={setFileInfo} label="Instructor Avatar" />
        <ModalFooter>
          <PrimaryButton text="Submit" type="submit" loading={isLoading || isUpdating} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddInstructorFrom;

AddInstructorFrom.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  activeData: PropTypes.any,
  setEdit: PropTypes.func.isRequired,
};
