/* eslint-disable react/prop-types */
import React from "react";

const Footer = ({ id, nextTab, loading }) => {
  //  onClick = { nextTab };
  return (
    <div className="widget-btn">
      <button to="#" className="btn btn-black">
        Back
      </button>

      {id != "course" && (
        <button type="button" className="btn btn-info-light next_btn" onClick={nextTab}>
          SKIP
        </button>
      )}
      <button type="submit" className="btn btn-info-light next_btn">
        {!loading ? "Continue" : "Loading..."}
      </button>
    </div>
  );
};

export default Footer;
