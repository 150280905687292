import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import Chapter from "./Chapter";
import AddChapterForm from "./AddChapterForm";
import { useGetChaptersByIdQuery } from "../../../../services/admin/addCourse/chapter/chapter";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { deleteLesson } from "../../../../store/features/admin/lesson/lesson.api";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import AddAllLessons from "./AddAllLessons/AddAllLessons";
import AddVideoLessonModal from "./AddAllLessons/AddVideoLessonModal/AddVideoLessonModal";
import AddTextLessonModal from "./AddAllLessons/AddTextLessonModal/AddTextLessonModal";
import AddPdfLessonModal from "./AddAllLessons/AddPdfLessonModal/AddPdfLessonModal";
import AddQizLessonModal from "./AddAllLessons/AddQuizLessonModal";
import AddAssignmentLessonModal from "./AddAllLessons/AddAssignmentLessonModal/AddAssignmentLessonModal";
import AddPresentationLessonModal from "./AddAllLessons/AddPresentationLessonModal";

const AddCurriculum = ({ nextTab2, prevTab1 }) => {
  const { id } = useParams();
  const [openAddLessonModal, setOpenLessonModal] = useState(false);
  const [openChapternModal, setOpenChapternModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [activeChapter, setActiveChapter] = useState();
  const [activeLesson, setActiveLesson] = useState();
  const [editLesson, setEditLesson] = useState(false);
  const [openAddLessonsModal, setOpenAddLessonsModal] = useState(false);
  const [openAddVideoLessonModal, setOpenAddVideoLessonModal] = useState(false);
  const [openAddtextLessonModal, setOpenAddTextLessonModal] = useState(false);
  const [openAddPdfLessonModal, setOpenAddPdfLessonModal] = useState(false);
  const [openAddQuizLessonModal, setOpenAddQuizLessonModal] = useState(false);
  const [openAddAssignmentLessonModal, setOpenAddAssignmentLessonModal] = useState(false);
  const [openAddPresentationLessonModal, setOpenPresentationLessonModal] = useState(false);
  const [openWhichModal, setOpenWhichModal] = useState("");
  const { isSuccess, data: allChapters, refetch } = useGetChaptersByIdQuery(id);
  const lessonState = useAppSelector((state) => state.lessonSlice);
  const dispatch = useAppDispatch();

  const removeLesson = () => {
    dispatch(deleteLesson(activeLesson.id));
    setActiveLesson(null);
  };

  const openDeleteModals = () => {
    setOpenDeleteModal(true);
  };

  useEffect(() => {
    if (lessonState.is_success.deleteLesson || lessonState.is_success.addLesson) {
      refetch();
    }
  }, [lessonState.is_success.deleteLesson, lessonState.is_success.addLesson]);

  useEffect(() => {
    if (lessonState.is_success.deleteLesson) {
      setOpenDeleteModal(false);
    }
  }, [lessonState.is_success.deleteLesson]);

  useEffect(() => {
    if (openWhichModal === "Video") {
      setOpenAddVideoLessonModal(true);
      setOpenAddLessonsModal(false);
      setOpenWhichModal("");
    }
    if (openWhichModal === "Text") {
      setOpenAddTextLessonModal(true);
      setOpenAddLessonsModal(false);
      setOpenWhichModal("");
    }
    if (openWhichModal === "PDF") {
      setOpenAddPdfLessonModal(true);
      setOpenAddLessonsModal(false);
      setOpenWhichModal("");
    }
    if (openWhichModal === "Quiz") {
      setOpenAddQuizLessonModal(true);
      setOpenAddLessonsModal(false);
      setOpenWhichModal("");
    }
    if (openWhichModal === "Assignment") {
      setOpenAddAssignmentLessonModal(true);
      setOpenAddLessonsModal(false);
      setOpenWhichModal("");
    }
    if (openWhichModal === "Presentation") {
      setOpenPresentationLessonModal(true);
      setOpenAddLessonsModal(false);
      setOpenWhichModal("");
    }
  }, [openWhichModal]);

  useEffect(() => {
    if (activeLesson && !openDeleteModal) {
      switch (activeLesson?.type) {
        case "text":
          setOpenAddTextLessonModal(true);
          break;

        case "file":
          setOpenAddPdfLessonModal(true);
          break;

        case "other_video":
          setOpenAddVideoLessonModal(true);
          break;

        case "video":
          setOpenAddVideoLessonModal(true);
          break;

        case "quiz":
          setOpenAddQuizLessonModal(true);
          break;

        case "assignment":
          setOpenAddAssignmentLessonModal(true);
          break;

        case "presentation":
          setOpenPresentationLessonModal(true);
          break;

        default:
          return;
      }
    }
  }, [activeLesson]);

  return (
    <fieldset className="field-card" style={{ display: "block" }}>
      <div className="add-course-info">
        <AddChapterForm
          activeChapter={activeChapter}
          openChapternModal={openChapternModal}
          setOpenChapternModal={setOpenChapternModal}
          setActiveChapter={setActiveChapter}
        />
        {/* <AddLessonFrom
          modalOpen={openAddLessonModal}
          setActiveChapter={setActiveChapter}
          setModalOpen={setOpenLessonModal}
          activeChapter={activeChapter}
          setEditLesson={setEditLesson}
          editLesson={editLesson}
          activeLesson={activeLesson}
          setActiveLesson={setActiveLesson}
        /> */}

        {/* Add all lessons Modal  */}
        <AddAllLessons
          modalOpen={openAddLessonsModal}
          setModalOpen={setOpenAddLessonsModal}
          setOpenWhichModal={setOpenWhichModal}
        />

        {openAddVideoLessonModal && (
          <AddVideoLessonModal
            lesson={activeLesson}
            setLesson={setActiveLesson}
            chapter={activeChapter}
            modalOpen={openAddVideoLessonModal}
            setModalOpen={setOpenAddVideoLessonModal}
          />
        )}
        {openAddtextLessonModal && (
          <AddTextLessonModal
            lesson={activeLesson}
            setLesson={setActiveLesson}
            chapter={activeChapter}
            modalOpen={openAddtextLessonModal}
            setModalOpen={setOpenAddTextLessonModal}
          />
        )}
        {openAddPdfLessonModal && (
          <AddPdfLessonModal
            lesson={activeLesson}
            setLesson={setActiveLesson}
            chapter={activeChapter}
            modalOpen={openAddPdfLessonModal}
            setModalOpen={setOpenAddPdfLessonModal}
          />
        )}
        {openAddQuizLessonModal && (
          <AddQizLessonModal
            lesson={activeLesson}
            setLesson={setActiveLesson}
            chapter={activeChapter}
            modalOpen={openAddQuizLessonModal}
            setModalOpen={setOpenAddQuizLessonModal}
          />
        )}

        {openAddAssignmentLessonModal && (
          <AddAssignmentLessonModal
            lesson={activeLesson}
            setLesson={setActiveLesson}
            chapter={activeChapter}
            modalOpen={openAddAssignmentLessonModal}
            setModalOpen={setOpenAddAssignmentLessonModal}
          />
        )}
        {openAddPresentationLessonModal && (
          <AddPresentationLessonModal
            lesson={activeLesson}
            setLesson={setActiveLesson}
            chapter={activeChapter}
            modalOpen={openAddPresentationLessonModal}
            setModalOpen={setOpenPresentationLessonModal}
          />
        )}
        <DeleteModal
          title="Delete Lesson ?"
          modalOpen={openDeleteModal}
          setModalOpen={setOpenDeleteModal}
          onClick={removeLesson}
          description={`Are you sure you want to delete ${activeLesson?.name}`}
          loading={lessonState.is_loading.deleteLesson}
        />

        <div className="add-course-form">
          {isSuccess &&
            allChapters.data.map((chapter) => {
              return (
                <Chapter
                  key={chapter.id}
                  chapterId={chapter.id}
                  setOpenLessonModal={setOpenLessonModal}
                  data={chapter}
                  setActiveChapter={setActiveChapter}
                  setOpenChapternModal={setOpenChapternModal}
                  openDeleteModal={openDeleteModals}
                  setActiveLesson={setActiveLesson}
                  activeLesson={activeLesson}
                  setEditLesson={setEditLesson}
                  dispatch={dispatch}
                  setOpenAddLessonsModal={setOpenAddLessonsModal}
                />
              );
            })}
        </div>
        <Footer prevTab1={prevTab1} nextTab2={nextTab2} />
      </div>
    </fieldset>
  );
};

export default AddCurriculum;

AddCurriculum.propTypes = {
  nextTab2: PropTypes.any.isRequired,
  prevTab1: PropTypes.any.isRequired,
};
