/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import AudioFileUploader from "./AudioFileUploader";
import AudioUploadProgress from "../FileUploadProgresses/AudioUploadProgress";
import ScModal from "../../../../../../../shared/modal/CustomModal/ScModal";
import ScModalHeader from "../../../../../../../shared/modal/CustomModal/ScModalHeader";
import ScModalBody from "../../../../../../../shared/modal/CustomModal/ScModalBody";
import { PrimaryButton } from "../../../../../../../shared/button/Button";
import { useGetAudioUrlMutation } from "../../../../../../../services/admin/student/student";

const UploadAudioModal = ({ modalOpen, setModalOpen, page, setData, fileInfo, setFileInfo }) => {
  const [prrogress, setProgress] = useState(100);

  const [generateAudioUrl] = useGetAudioUrlMutation();

  const clearFile = () => {
    setFileInfo({});
  };
  const cancel = () => {
    setFileInfo({});
  };

  useEffect(() => {
    if (fileInfo?.file) {
      const formdata = new FormData();
      formdata.append("audio", fileInfo?.file);

      const audio = new Audio();
      const audioSrc = fileInfo?.file || page?.audio_url;
      audio.src = URL.createObjectURL(audioSrc);

      audio.onloadedmetadata = () => {
        generateAudioUrl(formdata)
          .unwrap()
          .then((res) => {
            const data = {
              page_number: page?.page_number,
              audio_url: res?.data,
              audio_duration: audio.duration,
            };

            setData((prev) =>
              prev.map((item) => {
                if (item?.page_number === page?.page_number) {
                  return {
                    ...item,
                    audio_url: res?.data,
                    audio_duration: audio.duration,
                  };
                }
                return item;
              }),
            );
          });
      };
    }
  }, [fileInfo]);

  return (
    <ScModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <ScModalHeader title={"Upload audio"} />
      <ScModalBody>
        <form>
          {!fileInfo.file && (
            <div className="form-group">
              <label className="add-course-label">Upload audio File</label>
              <AudioFileUploader setFileInfo={setFileInfo} />
            </div>
          )}

          {fileInfo.file && (
            <AudioUploadProgress clearFile={clearFile} progress={prrogress} setModalOpen={setModalOpen} />
          )}

          <div className="d-flex gap-2 justify-content-end mt-4">
            {fileInfo.file && <PrimaryButton type={"submit"} text={"Ok"} onClick={() => setModalOpen(false)} />}

            {fileInfo.file && <PrimaryButton type={"button"} text={"Cancel"} onClick={cancel} />}
          </div>
        </form>
      </ScModalBody>
    </ScModal>
  );
};

export default UploadAudioModal;
