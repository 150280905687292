import React from "react";
import PropTypes from "prop-types";

import { PrimaryButton } from "../../../../shared/button/Button";

const Topography = ({ setModalOpen, clearModal }) => {
  const openModal = () => {
    clearModal();
    setModalOpen(true);
  };
  return (
    <div className="sc_add_ciriculum_row">
      <div className="add-course-inner-header">
        <h4>Curriculum</h4>
      </div>
      <div className="add-course-section">
        <PrimaryButton text="Add Chapter" onClick={openModal} />
      </div>
    </div>
  );
};
Topography.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
};

export default Topography;
