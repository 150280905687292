import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import ErrorText from "../inputs/errorText/ErrorText";

const CustomTextField = ({ name, placeholder, control, defaultValue, error, label, required }) => {
  const errorMessage = error && error.message;

  return (
    <div className="form-group mb-0">
      <label className="add-course-label" htmlFor={name}>
        {label} <span className={errorMessage ? "sc_error__text" : ""}>{required && "*"}</span>
      </label>
      <div id="editor">
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <CKEditor
              editor={ClassicEditor}
              config={{
                placeholder,
                // removePlugins: ["Heading", "Link"],
                toolbar: ["Heading", "bold", "italic", "bulletedList", "numberedList", "blockQuote"],
              }}
              data={field.value}
              onChange={(event, editor) => {
                const data = editor.getData();
                field.onChange(data);
              }}
            />
          )}
        />
      </div>

      {errorMessage && <ErrorText error={errorMessage} />}
    </div>
  );
};

CustomTextField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
};

export default CustomTextField;
