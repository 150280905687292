import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../settings/urlConfig";
import { TosatMessage } from "../../../helpers/utils/toaster";
import { handleApiError } from "../../../helpers/utils/apiError/apiError";
import axiosInstance from "../../../settings/axiosInstance";
import localDb from "../../../helpers/utils/localDb/localDb";

export const getUserData = createAsyncThunk("getUserData", async (rejectWithValue) => {
  try {
    const res = await axiosInstance.get(`${BASE_URL}/me`);

    return res.data?.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updateUserProfile = createAsyncThunk("updateUserProfile", async (data, rejectWithValue) => {
  try {
    const res = await axiosInstance.put(`${BASE_URL}/update-profile`, data);
    TosatMessage(res.data);
    return res.data?.data;
  } catch (err) {
    handleApiError(err, rejectWithValue);
  }
});

export const loginUser = createAsyncThunk("loginUser", async (data, rejectWithValue) => {
  try {
    const res = await axios.post(`${BASE_URL}/login`, data);
    return res.data;
  } catch (err) {
    handleApiError(err, rejectWithValue);
  }
});

export const SingnUpUser = createAsyncThunk("SingnUpUser", async (data, rejectWithValue) => {
  try {
    const res = await axios.post(`${BASE_URL}/register`, data);
    return res.data;
  } catch (err) {
    handleApiError(err, rejectWithValue);
  }
});

export const forgotPassword = createAsyncThunk("forgotPassword", async (data, rejectWithValue) => {
  try {
    const res = await axios.post(`${BASE_URL}/forgot-password/send-reset-link`, data);
    TosatMessage(res.data);
    return res.data;
  } catch (err) {
    handleApiError(err, rejectWithValue);
  }
});

export const resetPassword = createAsyncThunk("resetPassword", async (data, rejectWithValue) => {
  try {
    const res = await axios.post(`${BASE_URL}/forgot-password/reset-password`, data);
    TosatMessage(res.data);
    return res.data;
  } catch (err) {
    handleApiError(err, rejectWithValue);
  }
});

export const logout = createAsyncThunk("logout", async (rejectWithValue) => {
  try {
    const res = await axiosInstance.post(`${BASE_URL}/logout`);
    localDb.clear();
    return res.data;
  } catch (err) {
    handleApiError(err, rejectWithValue);
  }
});
