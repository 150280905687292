import React from "react";
import { LoginForm } from "../../../components/auth/login";
import AuthLayout from "../../../layout/auth/AuthLayout";

const desc =
  "Don't miss out on the opportunity to expand your horizons. Join us today and discover the endless possibilities of learning";

const Login = () => {
  return (
    <AuthLayout description={desc}>
      <h1>Sign into Your Account</h1>
      <LoginForm />
    </AuthLayout>
  );
};

export default Login;
