import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";

import CourseCard from "./CourseCard/CourseCard";
import SearchBar from "../../../shared/searchBar/SearchBar";
import Empty from "../../../shared/Empty/Empty";
import { getStudentCourses } from "../../../store/features/Student/student.api";
import Loader from "../../../shared/Loader/Loader";
import { useEnrollStudentMutation } from "../../../services/admin/student/student";
import { TosatMessage } from "../../../helpers/utils/toaster";

const StudentCoursesComp = () => {
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);

  const [enrollToCourse, { isLoading: isEnrolling }] = useEnrollStudentMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [search] = useDebounce(searchText, 1000);
  const { courses, loading } = useSelector((state) => state.studentSlice);
  const { user } = useSelector((state) => state.authSlice);

  useEffect(() => {
    dispatch(getStudentCourses({ search, page }));
  }, [search, page]);

  const handleOnClick = (id, isEnroll) => {
    if (isEnroll === 0) {
      const data = {
        user_id: user?.id,
        course_id: id,
      };

      enrollToCourse(data).unwrap();
    }
    navigate(`/course-detail/${id}`);
  };

  return (
    <div className="container">
      <div className="student-widget-group p-0 mt-4">
        <div className="row">
          <div className="col-lg-12 ">
            <SearchBar searchText={searchText} setSearchText={setSearchText} />
            <div className="row">
              {loading ? (
                <Loader />
              ) : (
                <>
                  {courses?.data?.length > 0 ? (
                    courses?.data.map(({ id, image, name, progress, enrolled }) => (
                      <CourseCard
                        key={id}
                        imageUrl={image}
                        title={name}
                        progress={progress}
                        onClick={() => handleOnClick(id, enrolled)}
                        loading={isEnrolling}
                      />
                    ))
                  ) : (
                    <Empty emptyText="No Courses added." />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCoursesComp;
