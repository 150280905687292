import React from "react";
import AuthLayout from "../../../layout/auth/AuthLayout";
import SingupForm from "../../../components/auth/singup/SingupForm";

const desc =
  "Join our vibrant community of educators and creators by signing up today.Get started now and bring your courses to life!";
const SingnUp = () => {
  return (
    <AuthLayout description={desc}>
      <h1>Sign Up</h1>
      <SingupForm />
    </AuthLayout>
  );
};

export default SingnUp;
