/* eslint-disable react/prop-types */
import React from "react";
import { userAvatar } from "../../../helpers/utils/image/imagePath";
import { Edit, Trash2 } from "react-feather";

const InstructorTable = ({ setDeletepenModal, data, isSuccess, setActiveData, setOpenModal, setEdit }) => {
  const handleDelete = (data) => {
    setActiveData(data);
    setDeletepenModal(true);
  };

  const handleEdit = (data) => {
    setActiveData(data);
    setOpenModal(true);
    setEdit(true);
  };
  return (
    <div className="settings-widget">
      <div className="settings-inner-blk p-0">
        <div className="comman-space pb-0">
          <div className="settings-referral-blk table-responsive">
            {/* Instructor Users */}
            <table className="table table-nowrap">
              <thead>
                <tr>
                  <th>NAME</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {isSuccess &&
                  data.map((instructor) => {
                    return (
                      <tr className="sc-border-bottom" key={instructor.id}>
                        <td>
                          <div className="refer-avatar-blk d-flex align-items-center">
                            <img
                              src={instructor.image ?? userAvatar}
                              className="rounded-circle me-2  sc_user_avatar"
                              alt="Referred User Info"
                            />
                            <p>{instructor.name}</p>
                          </div>
                        </td>
                        <td className="sc_instructor_table_action">
                          <Edit size={18} className="mr-1 icon" onClick={() => handleEdit(instructor)} />
                          <Trash2 size={18} className="icon" onClick={() => handleDelete(instructor)} />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {/* Instructor List */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorTable;
