/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import Modal from "../../../../../../shared/modal/Modal";
import ModalFooter from "../../../../../../shared/modal/ModalFooter";
import { PrimaryButton } from "../../../../../../shared/button/Button";
import TextField from "../../../../../../shared/inputs/TextField/TextField";
import { addLessonApi } from "../../../../../../store/features/admin/lesson/lesson.api";
import CustomTextField from "../../../../../../shared/CKTextEditor/CustomTextField";

const validationSchema = yup.object().shape({
  name: yup.string().required().label("Title"),
  text: yup.string().required().label("Description"),
});

const AddTextLessonModal = ({ lesson, setLesson, chapter, modalOpen, setModalOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const [editMode, setMode] = useState(false);

  const dispatch = useDispatch();
  const { is_loading } = useSelector((state) => state.lessonSlice);

  const clear = () => {
    setModalOpen(false);
  };

  const submitForm = (values) => {
    const formData = {
      chapter_id: chapter?.id,
      type: "text",
      ...values,
    };

    dispatch(addLessonApi({ formData }));

    reset();
    setModalOpen(false);
  };

  useEffect(() => {
    if (lesson) {
      setMode(!editMode);
      reset(lesson);
    }
    return () => setLesson(null);
  }, [lesson]);

  return (
    <Modal
      title={(editMode ? "Edit" : "New") + " Lesson"}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      onClear={clear}
    >
      <form className="mt-3" onSubmit={handleSubmit(submitForm)}>
        <TextField name="name" label="Title" register={register} error={errors.name} />

        <CustomTextField control={control} name="text" label="Content" error={errors.text} />
        <ModalFooter>
          <PrimaryButton text="Submit" type="submit" loading={is_loading?.addLesson} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddTextLessonModal;
