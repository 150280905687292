import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import "./index.css"

import ErrorText from "../inputs/errorText/ErrorText";

const SelectBox = ({ name, control, defaultValue, error, label, required, options }) => {
  const errorMessage = error && error.message;

  return (
    <div className="form-group mb-0 sc__select__wrapper">
      <label className="add-course-label" htmlFor={name}>
        {label} <span className={errorMessage ? "sc_error__text" : ""}>{required && "*"}</span>
      </label>
      <div id="editor">
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <select {...field} id="question_type">
              <option value="">Select a question type</option>

              {options.map((item, index) => (
                <option key={index} value={item?.value}>
                  {item?.label}
                </option>
              ))}
            </select>
          )}
        />
      </div>

      {errorMessage && <ErrorText error={errorMessage} />}
    </div>
  );
};

SelectBox.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
};

export default SelectBox;
