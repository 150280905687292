/* eslint-disable react/prop-types */
import React from "react";
import { loginImage } from "../../helpers/utils/image/imagePath";

const LeftSideImageSection = ({ description }) => {
  return (
    <div className="col-md-6 login-bg">
      <div className="welcome-login">
        <div className="login-banner">
          <img src={loginImage} className="img-fluid" alt="Logo" />
        </div>
        <div className="mentor-course text-center">
          <h2>Welcome to ScholarsAi</h2>
          <p>
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam. */}
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LeftSideImageSection;
