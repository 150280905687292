import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import ROUTES from "../../helpers/constants/routes";
import { Book, BookOpen, FileText, UserCheck, Users } from "react-feather";

const AdminSidebarLinks = ({ activeMenu }) => {
  return (
    <ul>
      {/* <li className="nav-item">
                <Link
                  to="/instructor-dashboard"
                  className={
                    activeMenu === "Dashboard" ? "nav-item active" : "nav-item"
                  }
                >
                  <Home size={20} /> My Dashboard
                </Link>
              </li> */}

      {/* FOR ADMIN  */}

      <li className={activeMenu === ROUTES.DASHBOARD ? "nav-item active" : "nav-item"}>
        <Link to={ROUTES.DASHBOARD} className="nav-link">
          <Book size={20} />
          Courses
        </Link>
      </li>

      <li className={activeMenu === ROUTES.INSTRUCTORS ? "nav-item active" : "nav-item"}>
        <Link to={ROUTES.INSTRUCTORS} className="nav-link">
          <UserCheck size={20} />
          Instructors
        </Link>
      </li>

      <li className={activeMenu === ROUTES.STUDENTS ? "nav-item active" : "nav-item"}>
        <Link to={ROUTES.STUDENTS} className="nav-link">
          <Users size={20} />
          Students
        </Link>
      </li>

      <li className={activeMenu.includes(ROUTES.ADMIN_LEARNING_PATH) ? "nav-item active" : "nav-item"}>
        <Link to={ROUTES.ADMIN_LEARNING_PATH} className="nav-link">
          <BookOpen size={20} />
          Learning Paths
        </Link>
      </li>

      <li className={activeMenu.includes(ROUTES.ASSIGNMENTS) ? "nav-item active" : "nav-item"}>
        <Link to={ROUTES.ASSIGNMENTS} className="nav-link">
          <FileText size={20} />
            Assignments
        </Link>
      </li>
      

      {/* <li className="nav-item">
                  <Link to="/instructor-reviews" className={activeMenu === "Reviews" ? "nav-item active" : "nav-item"}>
                    <Star size={20} /> Reviews
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/instructor-earnings"
                    className={activeMenu === "Earnings" ? "nav-item active" : "nav-item"}
                  >
                    <PieChart size={20} /> Earnings
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/instructor-orders" className={activeMenu === "Orders" ? "nav-item active" : "nav-item"}>
                    <ShoppingBag size={20} /> Orders
                  </Link>
                </li> */}
      {/* <li className="nav-item">
        <Link
          to="/instructor-student-grid"
          className={activeMenu === ("StudentGrid" || "StudentList") ? "nav-item active" : "nav-item"}
        >
          <Users size={20} /> Students
        </Link>
      </li> */}
      {/* <li className="nav-item">
                  <Link to="/instructor-payouts" className={activeMenu === "Payouts" ? "nav-item active" : "nav-item"}>
                    <DollarSign size={20} /> Payouts
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/instructor-tickets" className={activeMenu === "Tickets" ? "nav-item active" : "nav-item"}>
                    <Server size={20} /> Support Tickets
                  </Link>
                </li> */}
    </ul>
  );
};

export default AdminSidebarLinks;

AdminSidebarLinks.propTypes = {
  activeMenu: PropTypes.string.isRequired,
};
