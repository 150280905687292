/* eslint-disable react/prop-types */
import React from "react";
import { Icon1, TimerStart } from "../../imagepath";
import { Link } from "react-router-dom";
import Filter from "./Filter";
import ROUTES from "../../../helpers/constants/routes";

const CoursesTable = ({ isSuccess, data, searchText, setSearchText, setFilterStatus }) => {
  return (
    <div className="comman-space pb-0">
      <Filter searchText={searchText} setSearchText={setSearchText} setFilterStatus={setFilterStatus} />

      <div className="settings-tickets-blk course-instruct-blk table-responsive">
        {/* Referred Users */}
        <table className="table table-nowrap mb-2">
          <thead>
            <tr>
              <th>COURSES</th>
              <th>STUDENTS</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {isSuccess &&
              data.map((course) => {
                return (
                  <tr key={course.id}>
                    <td>
                      <div className="sell-table-group d-flex align-items-center">
                        <div className="sell-group-img">
                          <Link to={`${ROUTES.ADD_NEW_COURSE}/${course.id}`}>
                            <img src={course.image} className="img-fluid sc_course_image_in_table" alt="" />
                          </Link>
                        </div>
                        <div className="sell-tabel-info">
                          <p>
                            <Link to={`${ROUTES.ADD_NEW_COURSE}/${course.id}`}>{course.name}</Link>
                          </p>
                          <div className="course-info d-flex align-items-center border-bottom-0 pb-0">
                            <div className="rating-img d-flex align-items-center">
                              <img src={Icon1} alt="" />
                              <p>{course.total_lessons} Lesson</p>
                            </div>
                            <div className="course-view d-flex align-items-center">
                              <img src={TimerStart} alt="" />
                              <p>{course.total_duration}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{course.total_students}</td>
                    <td>
                      <span
                        className={`badge text-capitalize ${course.status == "published" ? "info-low" : "info-inter"}`}
                      >
                        {course.status}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {/* Referred Users */}
      </div>
    </div>
  );
};

export default CoursesTable;
