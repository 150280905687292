import React from "react";
import { Link } from "react-router-dom";
import { fullLogo } from "../../helpers/utils/image/imagePath";
import {  BACK_TO_HOME_URL } from "../../settings/urlConfig";

const LogoAndBackSection = () => {
  return (
    <div className="img-logo">
      <img src={fullLogo} className="img-fluid" alt="Logo" />
      <div className="back-home">
        <Link to={`${BACK_TO_HOME_URL}`}>Back to Home</Link>
      </div>
    </div>
  );
};

export default LogoAndBackSection;
