import React from "react";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import { Trash2 } from "react-feather";
import PropTypes from "prop-types";

import TextField from "../../../../../../shared/inputs/TextField/TextField";
import { PrimaryButton } from "../../../../../../shared/button/Button";
import ErrorText from "../../../../../../shared/inputs/errorText/ErrorText";

const ChoiceForm = ({ type, questionIndex }) => {
  const {
    watch,
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `questions[${questionIndex}].options`,
  });

  return (
    <>
      {fields.map((option, optionIndex) => (
        <>
          <div className="add__choice__section">
            <TextField
              name={`questions[${questionIndex}].options[${optionIndex}].option`}
              label={`Option ${optionIndex + 1}`}
              register={register}
              error={
                errors?.questions &&
                errors?.questions[questionIndex]?.options &&
                errors?.questions[questionIndex]?.options[optionIndex]?.option
              }
            />

            <div className="checkbox__and__delete__choice__section d-flex justify-content-between align-items-center">
              <div className="radio__checkbox__wrapper">
                {type !== "single_answer" ? (
                  <input
                    id={`${optionIndex}checkbox`}
                    type="checkbox"
                    style={{ marginRight: 6 }}
                    {...register(`questions.${questionIndex}.options.${optionIndex}.is_correct`)}
                  />
                ) : (
                  <Controller
                    name={`questions.${questionIndex}.options.${optionIndex}.is_correct`}
                    control={control}
                    render={({ field }) => (
                      <input
                        type="radio"
                        {...field}
                        id={`${optionIndex}checkbox`}
                        style={{ marginRight: 6 }}
                        checked={watch(`questions[${questionIndex}].options[${optionIndex}].is_correct`) === true}
                        onChange={(e) => {
                          fields.forEach((opt, index) => {
                            if (index !== optionIndex) {
                              setValue(`questions[${questionIndex}].options[${index}].is_correct`, false);
                            }
                          });
                          field.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                )}
                <label htmlFor={`${optionIndex}checkbox`}>This is correct answer</label>
              </div>

              <button type="button" className="choice__delete__btn" onClick={() => remove(optionIndex)}>
                <Trash2 />
                Delete choice
              </button>
            </div>
          </div>
          <ErrorText error={errors?.questions && errors?.questions[questionIndex]?.isCorrect?.message} />

          <div className="d-flex justify-content-end add__choice__btn">
            <PrimaryButton
              text="Add Choice"
              type="button"
              onClick={() => append({ option: "", is_correct: false }, { shouldFocus: true })}
            />
          </div>
        </>
      ))}
    </>
  );
};

ChoiceForm.propTypes = {
  type: PropTypes.string.isRequired,
  questionIndex: PropTypes.number.isRequired,
};

export default ChoiceForm;
