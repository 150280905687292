import React, { useState } from "react";
import { useDebounce } from "use-debounce";
import CoursesTable from "../../../components/admin/courses/CoursesTable";
import CoursesTypography from "../../../shared/Typography/CoursesTypography";
import CoursesMainWrapper from "./Wrapper";
import { useGetCoursesQuery } from "../../../services/admin/course/courses";
import Pagination from "../../../shared/pagination/Pagination";
import { useRef } from "react";

const Courses = () => {
  const [page, setPage] = useState(1);
  const timestampRef = useRef(Date.now()).current;

  const [searchText, setSearchText] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [debouncedSerach] = useDebounce(searchText, 500);
  const [debouncedPage] = useDebounce(page, 400);
  const { isSuccess, data } = useGetCoursesQuery({
    debouncedSerach,
    debouncedPage,
    filterStatus,
    sessionId: timestampRef,
  });
  return (
    <CoursesMainWrapper>
      <CoursesTypography text="Courses" subtext="Manage your courses and its update like live, draft and insight." />
      <CoursesTable
        isSuccess={isSuccess}
        data={data?.data?.data}
        searchText={searchText}
        setSearchText={setSearchText}
        setFilterStatus={setFilterStatus}
      />
      <Pagination data={data?.data?.pagination} setPage={setPage} />
    </CoursesMainWrapper>
  );
};

export default Courses;
