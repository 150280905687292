import React from "react";
import AuthLayout from "../../../layout/auth/AuthLayout";
import ForgotPasswordForm from "../../../components/auth/forgotPassword/ForgotPasswordForm";

const desc =
  "Simply enter the email address linked to your account, and we'll assist you  in restoring access to your online course creation platform.";
const ForgotPassword = () => {
  return (
    <AuthLayout description={desc}>
      <h1>Forgot Password ?</h1>

      <ForgotPasswordForm />
    </AuthLayout>
  );
};

export default ForgotPassword;
