import { createSlice } from "@reduxjs/toolkit";
import {
  getUserData,
  loginUser,
  SingnUpUser,
  forgotPassword,
  resetPassword,
  logout,
  updateUserProfile,
} from "./auth.api";
import localDb from "../../../helpers/utils/localDb/localDb";

const initialState = {
  is_loading: {
    login: false,
    singup: false,
    logout: false,
    forgotPassword: false,
    resetPassword: false,
  },

  is_success: {
    login: false,
    singup: false,
    logout: false,
    forgotPassword: false,
    resetPassword: false,
  },
  is_authenticated: false,
  // data: {},
  user: {},
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload;
    });
    builder.addCase(getUserData.rejected, (state) => {
      state.loading = false;
    });

    //update user profile
    builder.addCase(updateUserProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserProfile.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload;
    });
    builder.addCase(updateUserProfile.rejected, (state) => {
      state.loading = false;
    });

    // LOGIN USER
    builder.addCase(loginUser.pending, (state) => {
      state.is_loading.login = true;
      state.is_success.login = false;
      state.is_success.logout = false;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.is_loading.login = false;
      state.is_success.login = true;
      // state.data = payload.data;
      state.is_authenticated = true;
      localDb.save("access_token", payload.access_token);
      localDb.save("user", payload.user);
      state.user = payload?.user;
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.is_loading.login = false;
      state.is_success.login = false;
    });

    // SINGUP USER
    builder.addCase(SingnUpUser.pending, (state) => {
      state.is_loading.singup = true;
      state.is_success.singup = false;
    });
    builder.addCase(SingnUpUser.fulfilled, (state, { payload }) => {
      state.is_loading.singup = false;
      state.is_success.singup = true;
    });
    builder.addCase(SingnUpUser.rejected, (state) => {
      state.is_loading.singup = false;
      state.is_success.singup = false;
    });

    // FORGOT PASSWORD
    builder.addCase(forgotPassword.pending, (state) => {
      state.is_loading.forgotPassword = true;
      state.is_success.forgotPassword = false;
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.is_loading.forgotPassword = false;
      state.is_success.forgotPassword = true;
    });
    builder.addCase(forgotPassword.rejected, (state) => {
      state.is_loading.forgotPassword = false;
    });

    // RESET PASSWORD
    builder.addCase(resetPassword.pending, (state) => {
      state.is_loading.resetPassword = true;
      state.is_success.resetPassword = false;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.is_loading.resetPassword = false;
      state.is_success.resetPassword = true;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.is_loading.resetPassword = false;
    });

    // LOGOUT
    builder.addCase(logout.pending, (state) => {
      state.is_loading.logout = true;
      state.is_success.logout = false;
    });
    builder.addCase(logout.fulfilled, (state, { payload }) => {
      state.is_loading.logout = false;
      state.is_success.logout = true;
      // state.data = {};
      state.user = {};
      state.is_success.login = false;
      state.is_authenticated = false;
    });
    builder.addCase(logout.rejected, (state) => {
      state.is_loading.logout = false;
      state.is_success.logout = false;
    });
  },
});

export default authSlice.reducer;
