export default function getYoutubeVideoId(url) {
  if (url.includes("youtu.be")) {
    const shortUrlParts = url.split("/");
    return shortUrlParts[shortUrlParts.length - 1];
  } else {
    const urlParts = url.split(/[/=?&]/);
    const idIndex = urlParts.findIndex((part) => part === "v");
    return urlParts[idIndex + 1];
  }
}
