import React from "react";
import SudentCoursesMainWrapper from "../../../components/students/wrapper/StudentCoursesMainWrapper";
import CoursesTypography from "../../../shared/Typography/CoursesTypography";
import StudentProfile from "../../../components/students/Profile";

const Profile = () => {
  return (
    <SudentCoursesMainWrapper>
      <CoursesTypography text="Profile Details" subtext="You have full control to manage your own account setting." />
      <StudentProfile />
    </SudentCoursesMainWrapper>
  );
};

export default Profile;
