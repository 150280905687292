import React from "react";
import { Route, Routes } from "react-router-dom";
import ROUTES from "../helpers/constants/routes";
import Dashboards from "../pages/admin/dashboard";
import Courses from "../pages/admin/courses";
import AddNewCourse from "../pages/admin/addNewCourse";
import Instructors from "../pages/admin/instructors";
import Students from "../pages/admin/students";
import Admin from "./middleware/Admin";
import AdminLearningPath from "../pages/admin/learningPath";
import AdminLearningPathDetail from "../pages/admin/learningPathDetail";
import Assignments from "../pages/admin/assignments";

const AdminRoute = () => {
  return (
    <Routes>
      <Route element={<Admin />}>
        {/* <Route path={ROUTES.DASHBOARD} element={<Dashboards />} /> */}
        <Route path={ROUTES.DASHBOARD} element={<Courses />} />
        <Route path={`${ROUTES.ADD_NEW_COURSE}?/:id`} exact element={<AddNewCourse />} />
        <Route path={ROUTES.INSTRUCTORS} element={<Instructors />} />
        <Route path={ROUTES.STUDENTS} element={<Students />} />
        <Route path={ROUTES.ADMIN_LEARNING_PATH} element={<AdminLearningPath />} />
        <Route path={ROUTES.ADMIN_LEARNING_PATH_DETAILS} element={<AdminLearningPathDetail />} />
        <Route path={ROUTES.ASSIGNMENTS} element={<Assignments />} />
      </Route>
    </Routes>
  );
};

export default AdminRoute;
