/* eslint-disable react/prop-types */
import React from "react";
import { Icon1 } from "../../../helpers/utils/image/imagePath";
import PropTypes from "prop-types";

const LearningPathCoursesItem = ({ title, onClick }) => {
  return (
    <div className="course-info d-flex align-items-center course__title" onClick={onClick} style={{borderBottom:"1px solid #F0F0F0"}}>
      <div className="rating-img d-flex align-items-center">
        <img src={Icon1} alt="" />
        <p>{title}</p>
      </div>
    </div>
  );
};

export default LearningPathCoursesItem;

LearningPathCoursesItem.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
