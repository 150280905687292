/* eslint-disable react/prop-types */
import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import ReactPaginate from "react-paginate";
import "./index.css";

const Pagination = ({ data, setPage }) => {
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  if (data?.total > 10) {
    return (
      <div className="custom__react__pagination">
        {" "}
        <ReactPaginate
          breakLabel="..."
          nextLabel={<ChevronRight size={20} className="next__icon" />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={data?.total_pages}
          previousLabel={<ChevronLeft size={20} className="next__icon" />}
          renderOnZeroPageCount={null}
          containerClassName="react__paginate__button__container"
          pageClassName="sc_pagination_button"
          pageLinkClassName="pageLinkClassName"
          breakClassName="sc_pagination_button"
          nextClassName="nextClassName sc_next_and_prev_button sc_pagination_button"
          previousClassName="previousClassName sc_next_and_prev_button sc_pagination_button"
          activeClassName="activeClassName sc_pagination_button"
        />
      </div>
    );
  }
  return null;
};

export default Pagination;
