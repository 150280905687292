/* eslint-disable react/prop-types */
import React from "react";

const ScModalHeader = ({ title }) => {
  return (
    <div className="sc__modal__header">
      <p>{title}</p>
    </div>
  );
};

export default ScModalHeader;
