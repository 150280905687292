import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import PropTypes from "prop-types";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { ChevronLeft } from "react-feather";

const ViewPdfInLargeScreen = ({ url, setModalOpen }) => {
  return (
    <div className="lg__pdf__wrapper">
      <div className="lg__pdf__container">
        <div className="goback" onClick={() => setModalOpen(false)}>
          <ChevronLeft size={20} />
          Go Back
        </div>

        <div className="lg__pdf" style={{ height: "710px", width: "680px", overflowY: "scroll" }}>
          <Page pageNumber={url} />
        </div>
      </div>
    </div>
  );
};

export default ViewPdfInLargeScreen;

ViewPdfInLargeScreen.propTypes = {
  url: PropTypes.string.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};
