import { createSlice } from "@reduxjs/toolkit";
import { getCourseDetail, getLearningPath, getLearningPaths, getStudentCourses } from "./student.api";

const initialState = {
  loading: false,
  courses: [],
  course: null,
  lesson: null,
  learningPaths: [],
  learningPath: null,
};

const studentSlice = createSlice({
  name: "studentSlice",
  initialState,
  reducers: {
    setCurrentLesson: (state, { payload }) => {
      state.lesson = payload;
    },
    setCurrentCourse: (state, { payload }) => {
      state.course = payload;
    },
    setLearningPath: (state, { payload }) => {
      state.course = payload;
    },
  },
  extraReducers: (builder) => {
    //get student courses
    builder.addCase(getStudentCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStudentCourses.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.courses = payload?.data;
    });
    builder.addCase(getStudentCourses.rejected, (state) => {
      state.loading = false;
    });

    //get course detail
    builder.addCase(getCourseDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCourseDetail.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.course = payload?.data;
    });
    builder.addCase(getCourseDetail.rejected, (state) => {
      state.loading = false;
    });

    //get student learning paths
    builder.addCase(getLearningPaths.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLearningPaths.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.learningPaths = payload?.data;
    });
    builder.addCase(getLearningPaths.rejected, (state) => {
      state.loading = false;
    });

    //get learning path detail
    builder.addCase(getLearningPath.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLearningPath.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.learningPath = payload?.data;
    });
    builder.addCase(getLearningPath.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setCurrentLesson, setCurrentCourse, setLearningPath } = studentSlice.actions;

export default studentSlice.reducer;
