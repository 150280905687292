/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "../../../../helpers/utils/image/imagePath";
import { DeleteTextButton } from "../../../../shared/button/Button";
import { BUNNY_VIDEO_LIBRARY_ID } from "../../../../settings/urlConfig";

const VideoUploader = ({ setVideoFileInfo, clearVideo, videoRef, selectedVideoFileUrl, setSelectedVideoFileUrl }) => {
  const [localUrl, setLocalUrl] = useState(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    const videoUrl = URL.createObjectURL(acceptedFiles[0]);
    setSelectedVideoFileUrl(videoUrl);
    setVideoFileInfo({ file: acceptedFiles[0] });

    // const extension = getFileExtension(acceptedFiles[0].name);
    // const base64Image = await getBase64(acceptedFiles[0]);
  }, []);

  useEffect(() => {
    videoRef?.current?.load();
  }, [selectedVideoFileUrl]);

  const clearVideoCache = () => {
    clearVideo();
    setSelectedVideoFileUrl("");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "video/mp4": [".mp4", ".MP4", ".avi", ".AVI", ".MKV", ".mkv", ".mov", ".MOV"],
    },
  });

  useEffect(() => {
    if (selectedVideoFileUrl) {
      const isLocalUrl = selectedVideoFileUrl.includes("http://" || "https://");
      setLocalUrl(isLocalUrl);
    }
  }, [selectedVideoFileUrl]);

  return (
    <div className="form-control sc_basic_course_file_upload_section  ">
      {!selectedVideoFileUrl && (
        <div className="sc_file_upload_section" {...getRootProps()}>
          <img src={uploadFile} className="mb--05" />
          <span className="sc_upload_file_description">
            Drop your images here, or <span className="sc_upload_file_colorful_text">browse</span>
            <span className="second_desc">Supports MP4, AVI and MOV files</span>
          </span>
        </div>
      )}
      {selectedVideoFileUrl && (
        <div className="sc_show_selected_file_section">
          {!localUrl ? (
            <iframe
              width={380}
              height={180}
              id="bunny-player"
              src={`https://iframe.mediadelivery.net/embed/${BUNNY_VIDEO_LIBRARY_ID}/${selectedVideoFileUrl}?autoplay=false&loop=false&muted=false&preload=true`}
              loading="lazy"
              allowFullScreen
            ></iframe>
          ) : (
            <video width="380" height="180" controls ref={videoRef}>
              <source src={selectedVideoFileUrl} type="video/mp4" />
            </video>
          )}
          <div className="sc_delete_button_wrapper">
            <DeleteTextButton text="Remove Video" onClick={clearVideoCache} />
          </div>
        </div>
      )}
      <input {...getInputProps()} />
    </div>
  );
};

export default VideoUploader;
