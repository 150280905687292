import { toast } from "react-toastify";

export const TosatMessage = (data) => {
  if (data.success) {
    toast.success(data?.message);
  } else {
    toast.error(data?.message);
  }
};

export const TosatError = (error) => {
  toast.error(error?.response?.data?.message);
};

export const TosatServiceError = (error) => {
  toast.error(error?.data?.message);
};

export const TosatServiMessage = (message) => {
  toast.success(message);
};
