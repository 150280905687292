import React from "react";
import { useDebounce } from "use-debounce";

import AssignmentsHeader from "../../../components/admin/assignments/AssignmentsHeader";
import InstructorMainWrapper from "../instructors/Wrapper";
import AssignmentsTable from "../../../components/admin/assignments/AssignmentsTable";

const Assignments = () => {
  const [searchText, setSearchText] = React.useState("");

  const [query] = useDebounce(searchText, 500);

  return (
    <InstructorMainWrapper>
      <AssignmentsHeader search={searchText} setSearch={setSearchText} />
      <AssignmentsTable search={query} />
    </InstructorMainWrapper>
  );
};

export default Assignments;
