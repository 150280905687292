import React, { useState } from "react";
import ShowSuccess from "./ShowSuccess";
import BasicInfromation from "./basicInfromation";
import AddCurriculum from "./curriculum";
import AddSettings from "./settings/AddSettings";

const AddNewCourseTab = () => {
  const [TabChange, setTabChange] = useState(false);
  const [TabChange1, setTabChange1] = useState(false);
  const [TabChange2, setTabChange2] = useState(false);
  const [PageChange, setPageChange] = useState("basic");

  const nextTab = () => {
    setTabChange(true);
    setPageChange("curriculum");
  };

  const prevTab1 = () => {
    setTabChange(false);
    setPageChange("basic");
  };

  const nextTab2 = () => {
    setTabChange1(true);
    setTabChange(true);
    setPageChange("settings");
  };

  const prevTab2 = () => {
    setTabChange1(false);
    setPageChange("curriculum");
  };

  const nextTab3 = () => {
    setTabChange2(true);
    setTabChange(true);
    setPageChange("success");
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="widget-set">
            <div className="widget-setcount">
              <ul id="progressbar">
                <li className={TabChange ? "progress-activated" : "progress-active"}>
                  <p>
                    <span></span> Basic Information
                  </p>
                </li>
                <li className={TabChange1 ? "progress-activated" : "" || TabChange ? "progress-active" : ""}>
                  <p>
                    <span></span> Curriculum
                  </p>
                </li>
                <li className={TabChange2 ? "progress-activated" : "" || TabChange1 ? "progress-active" : ""}>
                  <p>
                    <span></span> settings
                  </p>
                </li>
              </ul>
            </div>

            <div className="widget-content multistep-form">
              {PageChange === "basic" ? <BasicInfromation nextTab={nextTab} /> : ""}
              {PageChange === "curriculum" ? <AddCurriculum nextTab2={nextTab2} prevTab1={prevTab1} /> : ""}
              {PageChange === "settings" ? <AddSettings nextTab3={nextTab3} prevTab2={prevTab2} /> : ""}
              {PageChange === "success" ? <ShowSuccess /> : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCourseTab;
