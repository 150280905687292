import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { propTypes } from "react-bootstrap/esm/Image";
import { BUNNY_VIDEO_LIBRARY_ID } from "../../../../settings/urlConfig";

const VideoCard = ({ title, videoUrl, onVideoEnd }) => {
  return (
    <div className="card content-sec">
      <div className="card-body">
        <div className="row">
          <div className="col-sm-6">
            <h5 className="video__introduction">{title}</h5>
          </div>
        </div>

        {videoUrl && <BunnyPlayer videoId={videoUrl} onEnded={onVideoEnd} />}
      </div>
    </div>
  );
};

export default VideoCard;

VideoCard.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onVideoEnd: PropTypes.func.isRequired,
};

const BunnyPlayer = ({ videoId, onEnded }) => {
  return (
    <div style={{ position: "relative", paddingTop: "56.25%" }}>
      <iframe
        id="bunny-player"
        src={`https://iframe.mediadelivery.net/embed/${BUNNY_VIDEO_LIBRARY_ID}/${videoId}?autoplay=false&loop=false&muted=false&preload=true`}
        loading="lazy"
        style={{ border: "none", position: "absolute", top: 0, height: "100%", width: "100%" }}
        allowFullScreen
      ></iframe>

      {/* <video  controls style={{ border: "none", position: "absolute", top: 0, height: "100%", width: "100%" }}>
        <source
          src={`https://iframe.mediadelivery.net/embed/${BUNNY_VIDEO_LIBRARY_ID}/${videoId}?autoplay=false&loop=false&muted=false&preload=true`}
        />
        Your browser does not support the video tag.
      </video> */}
    </div>
  );
};

BunnyPlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  onEnded: PropTypes.func.isRequired,
};
