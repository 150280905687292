import React from "react";
import { InstructorHeader } from "../../instructor/header";
import InstructorSidebar from "../sidebar";

export const Dashboard = () => {
  return (
    <div className="main-wrapper">
      <InstructorHeader activeMenu={"Dashboard"} />
      <div className="page-content instructor-page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu={"Dashboard"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}

            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
    </div>
  );
};
