/* eslint-disable react/prop-types */
import React from "react";
import Modal from "../../../../../shared/modal/Modal";
import { File, FileText, Video } from "react-feather";
import { assignmentIcon, quizIcon ,presentationIcon} from "../../../../../helpers/utils/image/imagePath";


const AddAllLessons = ({ modalOpen, setModalOpen,setOpenWhichModal}) => {

  const clearForm = () => {
    setModalOpen(false);
  };


  const openAddlessonModal = (e,item)=>{
    setOpenWhichModal(item.title);
  }
  const data = [
    {
        id:1,
        icon:<Video size={25} color="#F66962"/>,
        title:"Video",
        infoText:"Easy upload and display your video with ScholarsAI."
    },
    {
        id:2,
        icon:<FileText size={25} color="#F66962"/>,
        title:"Text",
        infoText:"Upload and showcase your text content with ScholarsAI."
    },
    {
        id:3,
        icon:<File size={25} color="#F66962"/>,
        title:"PDF",
        infoText:"Easy upload PDF content for your students to view directly."
    },
    {
        id:4,
        icon:quizIcon,
        title:"Quiz",
        infoText:"Allow students to interact with material that just presented."
    },
    {
      id:5,
      icon:assignmentIcon,
      title:"Assignment",
      infoText:"Upload any type of work for the students."
  },
  {
    id:6,
    icon:presentationIcon,
    title:"Presentation",
    infoText:"Upload any type of presentation for the students."
},
    
  ]
  
  return (
    <Modal title="Add Lesson" modalOpen={modalOpen} setModalOpen={setModalOpen} onClear={clearForm}>
      <div className="sc__add__lessons__wrapper">
        <div className="sc__add__lessons__container">
          {data.map((item) => (
            <div className="sc__lesson" key={item.id} onClick={(e)=>openAddlessonModal(e,item)} >
              <div className="sc__lesson__type">
                {item.title !== "Quiz" &&  item.title !== "Assignment" && item.title !== "Presentation"  && item.icon}
                {item.title === "Quiz" && (<img src={item.icon} alt="Quiz icon"/>)}
                {item.title === "Assignment" && (<img src={item.icon} alt="Assignment icon"/>)}
                {item.title === "Presentation" && (<img src={item.icon} alt="Presentation icon"/>)}
              </div>
              <div className="sc__lesson__info">
                <span>{item.title}</span>
                <p>{item.infoText}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AddAllLessons;
