import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CourseDetailsContent from "./CourseDetailsContent";
import CourseInstBannerSection from "./CourseInstBannerSection";
import CourseBreadCrumbBar from "./Contents/CourseBreadCrumbBar";
import Empty from "../../../shared/Empty/Empty";
import { getCourseDetail } from "../../../store/features/Student/student.api";
import { setCurrentCourse, setCurrentLesson } from "../../../store/features/Student/student.slice";
import Loader from "../../../shared/Loader/Loader";
import localDb from "../../../helpers/utils/localDb/localDb";

const CourseDetailsComp = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourseDetail(params.courseId));

    return () => {
      dispatch(setCurrentLesson(null));
      dispatch(setCurrentCourse(null));
    };
  }, []);

  const { course, loading } = useSelector((state) => state.studentSlice);

  function setDefaultIDs() {
    //check last played lesson
    const lastChapter = localDb.fetch("last_chapter_id");
    const lastLesson = localDb.fetch("last_lesson_id");

    if (lastChapter && lastLesson) {
      const chapter = course?.chapters?.find((item) => item.id === lastChapter);
      const lesson = chapter?.lessons?.find((item) => item.id === lastLesson);

      dispatch(setCurrentLesson(lesson));

      dispatch(setCurrentLesson(lesson));
    } else {
      const lesson = course?.chapters[0]?.lessons[0];

      localDb.save("last_chapter_id", course?.chapters[0]?.id);
      localDb.save("last_lesson_id", lesson?.id);
    }
  }

  useEffect(() => {
    if (course) {
      setDefaultIDs();
    }
  }, [course]);

  return loading ? (
    <Loader />
  ) : course ? (
    <div>
      <CourseBreadCrumbBar courseTitle={course?.name || ""} />
      <CourseInstBannerSection />
      <CourseDetailsContent />
    </div>
  ) : (
    <Empty emptyText="No Course details added." />
  );
};

export default CourseDetailsComp;
