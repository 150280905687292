import React from "react";
import AddNewCourseMainWrapper from "./Wrapper";
import AddNewCourseTopography from "../../../components/admin/addNewCourse/AddNewCourseTopography";
import AddNewCourseTab from "../../../components/admin/addNewCourse";

const AddNewCourse = () => {
  return (
    <AddNewCourseMainWrapper>
      <AddNewCourseTopography />
      <AddNewCourseTab />
    </AddNewCourseMainWrapper>
  );
};

export default AddNewCourse;
