import React from "react";
import { Link } from "react-router-dom";
import { GO_TO_PRIVACY_AND_POLICY_URL, GO_TO_TERMS_AND_CONDITION_URL } from "../../../settings/urlConfig";
import PropTypes from "prop-types"

const TermsAndService = ({agreedToTerms,setAgreedToTerms,setError}) => {
  const handleChnage = ()=>{
    setAgreedToTerms(!agreedToTerms);
    if(!agreedToTerms){
      setError("");
    }
    else{
      setError("Please agree to the terms and conditions.");
    }
  }
  return (
    <div className="form-check remember-me">
      <label className="form-check-label mb-0">
        <input className="form-check-input" type="checkbox" name="remember" checked={agreedToTerms}
          onChange={handleChnage} />I agree to the&nbsp;
        <Link to={`${GO_TO_TERMS_AND_CONDITION_URL}`}>Terms of Service</Link> and&nbsp;
        <Link to={`${GO_TO_PRIVACY_AND_POLICY_URL}`}>Privacy Policy.</Link>
      </label>
    </div>
  );
};

export default TermsAndService;
TermsAndService.propTypes ={
  agreedToTerms:PropTypes.bool.isRequired,
  setAgreedToTerms:PropTypes.func.isRequired,
  setError:PropTypes.func.isRequired

}
