import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FileUploader from "../../FileUploader";
import UploadProgress from "../../UploadProgress";
import Modal from "../../../../../../shared/modal/Modal";
import { defaultValues, generateValidationSchema, validationSchema } from "./data";
import ModalFooter from "../../../../../../shared/modal/ModalFooter";
import { PrimaryButton } from "../../../../../../shared/button/Button";
import TextArea from "../../../../../../shared/inputs/TextArea/TextArea";
import TextField from "../../../../../../shared/inputs/TextField/TextField";
import CustomTextField from "../../../../../../shared/CKTextEditor/CustomTextField";
import ErrorText from "../../../../../../shared/inputs/errorText/ErrorText";
import ShowPdf from "../AddPdfLessonModal/ShowPdf";
import { addLessonApi } from "../../../../../../store/features/admin/lesson/lesson.api";

const AddAssignmentLessonModal = ({ lesson, setLesson, chapter, modalOpen, setModalOpen }) => {
  const [fileInfo, setFileInfo] = useState({});
  const [editMode, setMode] = useState(false);
  const [editFile, setEditFiles] = useState(null);
  const [fileUploadedProgress, setFileUploadedProgress] = useState(100);

  const dispatch = useDispatch();
  const { is_loading, is_success } = useSelector((state) => state.lessonSlice);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(generateValidationSchema(editMode)),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (lesson) {
      setMode(!editMode);
      setEditFiles(lesson?.assignment?.files);
      reset({
        ...lesson?.assignment,
        name: lesson?.assignment?.title,
        id: lesson?.id,
      });
    }
    return () => setLesson(null);
  }, [lesson]);

  const clear = () => {
    setModalOpen(false);
    setMode(false);
    setEditFiles(null);
  };

  const clearFile = () => {
    setFileInfo({});
  };

  useEffect(() => {
    if (fileInfo.file) {
      setValue("assignment_templates", [fileInfo.file]);
    }
  }, [fileInfo]);

  const submitForm = (values) => {
    const formData = new FormData();

    editMode && formData.append("id", values?.id);
    editMode && formData.append("files", editFile);

    formData.append("type", "assignment");
    formData.append("name", values.name);
    formData.append("chapter_id", chapter?.id);
    formData.append("description", values.description);
    formData.append("submission_message", values.submission_message);

    values.assignment_templates?.map((item, index) => formData.append(`assignment_templates[${index}]`, item));

    dispatch(addLessonApi({ formData }));

    reset();
    clear();
  };

  return (
    <Modal
      title={(editMode ? "Edit" : "New") + " Assignment"}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      onClear={clear}
    >
      <form className="mt-3" onSubmit={handleSubmit(submitForm)}>
        <TextField name="name" label="Title" register={register} error={errors.name} />
        <CustomTextField control={control} name="description" label="Content" error={errors.description} />
        {!editFile && !fileInfo.file && (
          <div className="form-group mt-3">
            <label className="add-course-label">Example Template</label>
            <FileUploader setFileInfo={setFileInfo} />
            {errors.assignment_templates && <ErrorText error={errors.assignment_templates.message} />}
          </div>
        )}
        {editFile && !fileInfo.file && <ShowPdf url={editFile?.[0]?.url} handleRemove={() => setEditFiles(null)} />}
        <div className="form-group mt-3">
          {fileInfo.file && <UploadProgress progress={fileUploadedProgress} clearFile={clearFile} />}
        </div>
        <TextArea
          name="submission_message"
          label="Confirmation Message"
          register={register}
          error={errors.submission_message}
        />
        <ModalFooter>
          <PrimaryButton text="Submit" type="submit" loading={is_loading?.addLesson} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddAssignmentLessonModal;

AddAssignmentLessonModal.propTypes = {
  lesson: PropTypes.object.isRequired,
  setLesson: PropTypes.func.isRequired,
  chapter: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};
