import * as Yup from "yup";

export const defaultValues = {
  name: "",
  description: "",
  submission_message: "",
  assignment_templates: undefined,
};

export const generateValidationSchema = (editMode) =>
  Yup.object().shape({
    name: Yup.string().required().trim().label("Title"),
    assignment_templates: Yup.mixed().when([], () =>
      !editMode ? Yup.mixed().required().label("File") : Yup.mixed().label("File"),
    ),
  });
