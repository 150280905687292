/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { ChevronDown, ChevronUp, Trash2, Maximize2 } from "react-feather";
import { Page } from "react-pdf";

import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import AudioRecordModal from "../AudioRecordModal/AudioRecordModal";
import UploadAudioModal from "../AudioUploadModal/UploadAudioModal";
import { PrimaryButton } from "../../../../../../../shared/button/Button";
import ViewPdfSlide from "./ViewPdfSlide";
import song from "../../../../../../../assets/demo.mp3";
import ViewPdfInLargeScreen from "./ViewPdfInLargeScreen";

const Slide = ({ active, setActive, data, setData }) => {
  const [fileInfo, setFileInfo] = useState({});
  const [openAudioRecordModal, setOpenAudioRecordModal] = useState(false);
  const [openUploadAudioModal, setOpenUploadAudioModal] = useState(false);
  const [expandPdf, setExpandPdf] = useState(false);

  const uploadaudioFile = () => {
    setOpenUploadAudioModal(true);
  };
  const handleRecordAudio = () => {
    setOpenAudioRecordModal(true);
  };

  const getAudioUrl = () => {
    if (fileInfo?.file && !data?.audio_url) {
      return URL.createObjectURL(fileInfo?.file) || "";
    } else {
      return data?.audio_url;
    }
  };

  const clearAudio = () => {
    setFileInfo({});
    setData((prev) =>
      prev.map((page) => {
        if (page?.page_number === data?.page_number) {
          return {
            ...page,
            audio_url: "",
            audio_duration: 0,
          };
        }
        return page;
      }),
    );
  };

  return (
    <>
      <div className="slide__div">
        <div className="slide__wrapper" onClick={() => setActive(!active)}>
          <div className="slide__container d-flex justify-content-between  ">
            <div className="slide__count">Slide {data?.page_number}</div>
            <span>{active ? <ChevronUp size={20} /> : <ChevronDown size={20} />}</span>
          </div>
        </div>
        {active && (
          <div className="slide__content__wrapper">
            <div className="slide__content__container">
              <div className="row">
                <div className="col-12 pdf__section">
                  <p>Preview</p>
                  <div className="pdf__content__wrapper">
                    <div className="expand__pdf">
                      <Maximize2 size={20} onClick={() => setExpandPdf(true)} />
                    </div>
                    <div style={{ height: "520px", overflowY: "scroll" }}>
                      <Page pageNumber={data?.page_number} />
                      <p className="d-flex align-items-center text-center justify-content-center my-2">
                        Page {data?.page_number}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 audio__section">
                  <p>Audio (Supports aac, mp3, m4a)</p>
                  <div className="audio__content__wrapper">
                    <div className="audio__content__container">
                      <div className="buttons__wrapper d-flex">
                        <PrimaryButton type={"button"} text={" Record Audio"} onClick={handleRecordAudio} />
                        <button type="button" className="audio__Upload__btn" onClick={uploadaudioFile}>
                          Upload Audio
                        </button>
                      </div>
                    </div>
                    <div className="audios__list">
                      <div className="audio">
                        <div className="audio__info">
                          <div className="audio__name">{fileInfo?.file?.name}</div>
                          <div className="audio__delete" onClick={clearAudio}>
                            <Trash2 />
                          </div>
                        </div>
                        <audio controls src={getAudioUrl()}></audio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {openAudioRecordModal && (
   <AudioRecordModal
   modalOpen={openAudioRecordModal}
   setModalOpen={setOpenAudioRecordModal}
   page={data}
   setData={setData}
 />
      )}

      {openUploadAudioModal && (
        <UploadAudioModal
          modalOpen={openUploadAudioModal}
          setModalOpen={setOpenUploadAudioModal}
          fileInfo={fileInfo}
          setFileInfo={setFileInfo}
          page={data}
          setData={setData}
        />
      )}
      {expandPdf && <ViewPdfInLargeScreen url={data?.page_number} setModalOpen={setExpandPdf} />}
    </>
  );
};

export default Slide;
