/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

const ModalFooter = ({ children }) => {
  return <div className="sc_modal_button_section mt-3">{children}</div>;
};

export default ModalFooter;

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};
