/* eslint-disable react/prop-types */
import React from "react";
import { X } from "react-feather";
const AudioUploadProgress = ({ progress, clearFile }) => {

  return (
    <>
      <div className="mb-3 sc__upload__audio__progress">
        <div className="d-flex justify-content-between mb-2">
          <div className="sc_uploaded_file_name">new audio.mp3 7.5 mb </div>
          <X size={15} className="sc_cancel_upload__icon" onClick={clearFile} />
        </div>
        <div className="course-stip progress-stip file-upload">
          <div className="progress-bar active-stip sc_active__bg" style={{ width: progress + "%" }} />
        </div>
        <div className="sc_show_uploading_precentage mt-1"> (Uploading {progress}%)</div>
      </div>
    </>
  );
};

export default AudioUploadProgress;
