import React from "react";

const ProfileInfoTypography = () => {
  return (
    <div className="personal-info-head">
      <h4>Personal Details</h4>
      <p>Edit your personal information and address.</p>
    </div>
  );
};

export default ProfileInfoTypography;
