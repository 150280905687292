import React from "react";
import PropTypes from "prop-types";
import "./index.css";


export const PrimaryBadge = ({ text }) => {
  return (
    <span  className="sc__primary__badge" >
      { text }
    </span>
  );
};

export const SuccessBadge = ({ text }) => {
  return (
    <span  className="sc__success__badge" >
      { text }
    </span>
  );
};

export const DangerBadge = ({ text }) => {
  return (
    <span  className="sc__danger__badge">
      { text }
    </span>
  );
};

PrimaryBadge.propTypes = {
  text: PropTypes.string.isRequired,
};

SuccessBadge.propTypes = {
  text: PropTypes.string.isRequired,
};

DangerBadge.propTypes = {
  text: PropTypes.string.isRequired,
};
