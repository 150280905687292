import React, { useState } from "react";
import { useAppDispatch } from "../../../store/store";
import { forgotPassword } from "../../../store/features/Auth/auth.api";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const dispatch = useAppDispatch();

  const handleForm = (e) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    const data = {
      email,
    };

    dispatch(forgotPassword(data));
  };
  return (
    <>
      {" "}
      <div className="reset-password">
        <p>Enter your email to reset your password.</p>
      </div>
      <form onSubmit={handleForm}>
        <div className="form-group">
          <label className="form-control-label">Email</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
          />
        </div>
        <div className="d-grid">
          <button className="btn btn-start" type="submit">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
