import React, { useState, useEffect } from "react";

import {
  Bell,
  Book,
  DollarSign,
  Home,
  Lock,
  PieChart,
  Power,
  RefreshCw,
  Server,
  Settings,
  ShoppingBag,
  Star,
  Trash2,
  User,
  Users,
} from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StickyBox from "react-sticky-box";
import { userAvatar, userAvatarImageBg } from "../../helpers/utils/image/imagePath";
import ROUTES from "../../helpers/constants/routes";
import AdminSidebarLinks from "./AdminSidebarLinks";
import StudentSidebarLinks from "./StudentSidebarLinks";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { logout } from "../../store/features/Auth/auth.api";
import capitalizeFirstLetter from "../../helpers/capitalizeFirstLetter";
import { useSelector } from "react-redux";
import { ROLES } from "../../helpers/constants/roles";

// eslint-disable-next-line react/prop-types
export default function Sidebar() {
  const authState = useAppSelector((state) => state.authSlice);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [activeMenu, setActiveMenu] = useState("");
  const location = useLocation();

  const { user } = useSelector((state) => state.authSlice);

  useEffect(() => {
    setActiveMenu(location?.pathname);
  }, [location?.pathname]);

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (!authState.is_loading.logout && authState.is_success.logout) {
      navigate("/");
    }
  }, [authState.is_success.logout]);

  return (
    <div className="col-xl-3 col-lg-4 col-md-12 theiaStickySidebar">
      <StickyBox offsetTop={20} offsetBottom={20}>
        <div className="sideStickyBar">
          <div className="settings-widget dash-profile">
            <div className="settings-menu p-0">
              <div className="profile-bg">
                <img src={userAvatarImageBg} alt="" />
                <div className="profile-img">
                  <Link to="/instructor-profile">
                    <img src={user?.avatar_url || userAvatar} alt="" className="object-fit-cover" />
                  </Link>
                </div>
              </div>
              <div className="profile-group">
                <div className="profile-name text-center">
                  <h4>
                    <Link to="/instructor-profile">{`${user?.first_name ?? ""} ${user?.last_name ?? ""}`}</Link>
                  </h4>
                  <p>{capitalizeFirstLetter(user?.role ?? "")}</p>
                </div>
                {user?.role !== ROLES.STUDENT && (
                  <div className="go-dashboard text-center">
                    <Link to={ROUTES.ADD_NEW_COURSE} className="btn btn-primary">
                      Create New Course
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="settings-widget account-settings feather-icon">
            <div className="settings-menu">
              <h3>DASHBOARD</h3>
              {user.role == ROLES.ADMIN ? (
                <AdminSidebarLinks activeMenu={activeMenu} />
              ) : (
                <StudentSidebarLinks activeMenu={activeMenu} />
              )}

              <div className="instructor-title">
                <h3>ACCOUNT SETTINGS</h3>
              </div>
              <ul>
                {/* <li className="nav-item">
                  <Link
                    to="/instructor-edit-profile"
                    className={activeMenu === "EditProfile" ? "nav-item active" : "nav-item"}
                  >
                    <Settings size={20} /> Edit Profile
                  </Link>
                </li> */}

                <li className="nav-item">
                  <Link to="/profile" className={activeMenu === "EditProfile" ? "nav-item active" : "nav-item"}>
                    <Settings size={20} /> Edit Profile
                  </Link>
                </li>

                {/* <li className="nav-item">
                  <Link
                    to="/instructor-security"
                    className={activeMenu === "Security" ? "nav-item active" : "nav-item"}
                  >
                    <User size={20} /> Security
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/instructor-social-profile"
                    className={activeMenu === "SocialProfile" ? "nav-item active" : "nav-item"}
                  >
                    <RefreshCw size={20} /> Social Profiles
                  </Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link
                    to="/instructor-notifications"
                    className={activeMenu === "Notification" ? "nav-item active" : "nav-item"}
                  >
                    <Bell size={20} /> Notifications
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/instructor-profile-privacy"
                    className={activeMenu === "ProfilePrivacy" ? "nav-item active" : "nav-item"}
                  >
                    <Lock size={20} /> Profile Privacy
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/instructor-delete-profile"
                    className={activeMenu === "DeleteProfile" ? "nav-item active" : "nav-item"}
                  >
                    <Trash2 size={20} /> Delete Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/instructor-linked-account"
                    className={activeMenu === "LinkedAccount" ? "nav-item active" : "nav-item"}
                  >
                    <User size={20} /> Linked Accounts
                  </Link>
                </li> */}
                <li className="nav-item" onClick={handleLogout}>
                  <Link className="nav-link">
                    <Power size={20} /> Sign Out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </StickyBox>
    </div>
  );
}
