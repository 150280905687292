import PropTypes from "prop-types";
import React from "react";

const Message = ({ validationError }) => {
  if (validationError == 1) {
    return "";
  } else if (validationError == 2) {
    return (
      <span id="poor" className="active" style={{ fontSize: 12, color: "#DC3545" }}>
        😠 Weak. Must contain at least 8 characters
      </span>
    );
  } else if (validationError == 3) {
    return (
      <span id="weak" className="active" style={{ fontSize: 12, color: "#FFC107" }}>
        😲 Average. Must contain at least 1 letter or number
      </span>
    );
  } else if (validationError == 4) {
    return (
      <span id="strong" className="active" style={{ fontSize: 12, color: "#0D6EFD" }}>
        🙂 Almost. Must contain special symbol
      </span>
    );
  } else if (validationError == 5) {
    return (
      <span id="heavy" className="active" style={{ fontSize: 12, color: "#4BB543" }}>
        😊 Awesome! You have a secure password.
      </span>
    );
  }
};

export default Message;

Message.propTypes = {
  validationError: PropTypes.number.isRequired,
};
