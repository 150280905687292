import React, { useState } from "react";
import { courseImage } from "../../../../helpers/utils/image/imagePath";
import PropTypes from "prop-types";

const CourseCard = ({ imageUrl, title, progress, onClick, loading }) => {
  return (
    <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
      <div className="course-box course-design d-flex ">
        <div className="product">
          <div className="product-img">
            <img className="img-fluid course__card__img__style" alt="" src={imageUrl ? imageUrl : courseImage} />
          </div>
          <div className="product-content">
            <h3 className="title" onClick={onClick}>
              <div className="course__title">{title}</div>
            </h3>

            <div className="progress-stip">
              <div className="progress-bar bg-success progress-bar-striped " style={{ width: `${progress}%` }}></div>
            </div>
            <div className="student-percent">
              <p>{progress}% Completed</p>
            </div>
            <div className="start-leason hoverBlue d-flex align-items-center">
              <button type="button" className="btn btn-primary" onClick={onClick} disabled={loading}>
                Start Course
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;

CourseCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
