import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import SearchBar from "../../../shared/searchBar/SearchBar";
import LearningPathCoursesItem from "./LearningPathCoursesItem";
import Loader from "../../../shared/Loader/Loader";
import Empty from "../../../shared/Empty/Empty";
import { getLearningPath } from "../../../store/features/Student/student.api";

const LearningPathCoursesComp = () => {
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { learningPath, loading } = useSelector((state) => state.studentSlice);

  const { id } = useParams();

  const [search] = useDebounce(searchText, 1000);

  useEffect(() => {
    dispatch(getLearningPath({ id, params: { search } }));
  }, [search]);

  const handleOnClick = (courseId) => {
    navigate(`/course-detail/${courseId}`);
  };

  return (
    <div className="container">
      <div className="student-widget-group p-0 mt-4">
        <div className="row">
          <div className="col-lg-12 ">
            <SearchBar searchText={searchText} setSearchText={setSearchText} />

            <div className="row">
              <div className="courses__title mb-2">COURSES IN LEARNING PATH</div>

              {loading ? (
                <Loader />
              ) : (
                <>
                  {learningPath?.courses?.length > 0 ? (
                    learningPath?.courses.map((item) => (
                      <LearningPathCoursesItem key={item.id} title={item.name} onClick={() => handleOnClick(item.id)} />
                    ))
                  ) : (
                    <Empty emptyText="No Courses added in learning path." />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningPathCoursesComp;
