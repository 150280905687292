import React from "react";
import SudentCoursesMainWrapper from "../../../components/students/wrapper/StudentCoursesMainWrapper";
import LearningPathListAndForm from "../../../components/admin/learningPath";
import CoursesTypography from "../../../shared/Typography/CoursesTypography";
import LearningPathDetailsList from "../../../components/admin/learningPathDetail";

const AdminLearningPathDetail = () => {
  return (
    <SudentCoursesMainWrapper>
      <CoursesTypography text="Learning Path" subtext="Manage your learning paths from here." />
      <LearningPathDetailsList />
    </SudentCoursesMainWrapper>
  );
};

export default AdminLearningPathDetail;
