export default function validateParams(params) {
  let query = {};

  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined || value === "" || value === null) {
      return;
    }
    query[key] = value;
  });

  return new URLSearchParams(query);
}
