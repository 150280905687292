import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Play, Lock } from "../../../../helpers/utils/image/imagePath";

const LessonCard = ({ data, onClick, currentIndex }) => {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    setActive(currentIndex === data?.id);
  }, [data, currentIndex]);

  return (
    <li
      className={`border-0 pb-0 course__content__title ${isActive && "active__title"}`}
      role="button"
      onClick={onClick}
    >
      <p style={{ paddingRight: "10px" }}>{data?.name}</p>
      <div>
        <img src={isActive ? Play : Lock} alt="" className="me-2" />
      </div>
    </li>
  );
};

export default LessonCard;

LessonCard.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
};
