import React, { useState } from "react";
import PropTypes from "prop-types";
import { userAvatar } from "../../../helpers/utils/image/imagePath";
import { ResendInvitationButton } from "../../../shared/button/Button";
import { useSendInvitationMutation } from "../../../services/admin/student/student";

const StudentTable = ({ isSuccess, data }) => {
  const [sendInvitation, { isLoading }] = useSendInvitationMutation();
  const [loadingId, setLoadingId] = useState(0);

  const handleResendInvitation = (id) => {
    setLoadingId(id);
    sendInvitation(id);
  };
  return (
    <div className="settings-widget">
      <div className="settings-inner-blk p-0">
        <div className="comman-space pb-0">
          <div className="settings-referral-blk table-responsive">
            {/* Instructor Users */}
            <table className="table table-nowrap">
              <thead>
                <tr>
                  <th>NAME</th>
                  <th>ENROLLED</th>
                  <th>PROGRESS</th>
                  <th>LOCATIONS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {isSuccess &&
                  data.map((student) => {
                    return (
                      <tr key={student.id}>
                        <td>
                          <div className="refer-avatar-blk d-flex align-items-center">
                            <img
                              src={student.avatar ?? userAvatar}
                              className="rounded-circle me-2 sc_user_avatar"
                              alt="Referred User Info"
                            />
                            <p>{student.name}</p>
                          </div>
                        </td>
                        <td className="px-2">{student.enrolled}</td>
                        <td className="px-2">{student.progress}%</td>
                        <td className="px-2">{student.location}</td>
                        <td className="px-2 ">
                          {student.email_verified_at ? (
                            <span className="badge badge-success">Email Verified</span>
                          ) : (
                            <ResendInvitationButton
                              text="Resend Invitation"
                              onClick={() => handleResendInvitation(student.id)}
                              loading={isLoading && loadingId == student.id}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {/* Instructor List */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentTable;

StudentTable.propTypes = {
  isSuccess: PropTypes.bool.isRequired,
  data: PropTypes.array,
};
