import React from "react";
import PropTypes from "prop-types";

const CoursesMainWrapper = ({ children }) => {
  return (
    <div className="col-xl-9 col-lg-8 col-md-12">
      <div className="row">
        <div className="col-md-12">
          <div className="settings-widget">
            <div className="settings-inner-blk p-0">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesMainWrapper;
CoursesMainWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
