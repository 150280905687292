import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LearningPathCourseCard from "../../../shared/Card/LearningPathCourseCard";
import SearchBar from "../../../shared/searchBar/SearchBar";
import Empty from "../../../shared/Empty/Empty";
import Loader from "../../../shared/Loader/Loader";
import { getLearningPaths } from "../../../store/features/Student/student.api";
import { setLearningPath } from "../../../store/features/Student/student.slice";

const LearningPathsComp = () => {
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [search] = useDebounce(searchText, 1000);
  const { learningPaths, loading } = useSelector((state) => state.studentSlice);

  useEffect(() => {
    dispatch(getLearningPaths({ search, page }));
    return () => {
      dispatch(setLearningPath(null));
    };
  }, [search, page]);

  const handleOnClick = (id) => {
    navigate(`/learning-path-courses/${id}`);
  };

  return (
    <div className="container">
      <div className="student-widget-group p-0 mt-4">
        <div className="row">
          <div className="col-lg-12 ">
            <SearchBar searchText={searchText} setSearchText={setSearchText} />

            <div className="row">
              {loading ? (
                <Loader />
              ) : (
                <>
                  {learningPaths?.data?.length > 0 ? (
                    learningPaths?.data.map((item) => (
                      <LearningPathCourseCard
                        key={item.id}
                        title={item.name}
                        imageUrl={item.image}
                        courseCount={item?.courses?.length}
                        courseDuration={item?.duration || 0}
                        courseDescription={item.description}
                        onClick={() => handleOnClick(item?.id)}
                      />
                    ))
                  ) : (
                    <Empty emptyText="No Learning paths added." />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningPathsComp;
