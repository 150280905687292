/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDebounce } from "use-debounce";

import Modal from "../../../shared/modal/Modal";
import SearchBar from "../../../shared/searchBar/SearchBar";
import InputLabel from "../../../shared/inputs/inputLabel/InputLabel";
import { Search } from "react-feather";
import FullWithSearch from "../../../shared/fullWidthSearch/FullWithSearch";
import {
  useDeleteCourseFromLearningPathMutation,
  useSaveCourseToLearningPathMutation,
} from "../../../services/admin/learningPathDetail/learningPathDetail";
import { useParams } from "react-router-dom";

const AddFrom = ({ isFetched, data, preservedData, openAddModal, setOpenAddModal, setAllCourses }) => {
  const [searchText, setSearchText] = useState("");
  const [debouncedSerach] = useDebounce(searchText, 500);
  const { id } = useParams();
  const [saveCourseToLearningPath, { isLoading, isSuccess: isAdded, data: addedData }] =
    useSaveCourseToLearningPathMutation();

  const clear = () => {};

  const saveCourse = (courseId) => {
    const data = { course_id: courseId };
    saveCourseToLearningPath({ id, data });
    setOpenAddModal(false);
  };

  useEffect(() => {
    if (isAdded) {
      const arr = data.filter((course) => {
        if (course.id !== addedData?.data.id) {
          return course;
        }
      });
      setAllCourses(arr);
    }
  }, [isAdded]);

  useEffect(() => {
    if (debouncedSerach) {
      //
      const arr = preservedData.filter((data) => data.name.toLowerCase().includes(debouncedSerach.toLowerCase()));
      setAllCourses(arr);
    } else {
      setAllCourses(preservedData);
    }
  }, [debouncedSerach]);

  return (
    <Modal title="Add Courses" modalOpen={openAddModal} setModalOpen={setOpenAddModal} onClear={clear}>
      <div className="mt-2" />
      <InputLabel htmlFor="" label="Course Name" />
      <FullWithSearch searchText={searchText} setSearchText={setSearchText} />

      <div className="sc_learning_path_course_list_section sc_hide_scrollbar">
        {isFetched &&
          data.map((course) => {
            return (
              <span role="button" key={course.id} onClick={() => saveCourse(course.id)}>
                {course.name}
              </span>
            );
          })}
      </div>
    </Modal>
  );
};

export default AddFrom;
AddFrom.propTypes = {
  isFetched: PropTypes.bool.isRequired,
  openAddModal: PropTypes.bool.isRequired,
  setOpenAddModal: PropTypes.func.isRequired,
  data: PropTypes.object,
  preservedData: PropTypes.object,
};
