/* eslint-disable react/prop-types */
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "../../../../../../../helpers/utils/image/imagePath";

const AudioFileUploader = ({ setFileInfo }) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    setFileInfo({ file: acceptedFiles[0] });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'audio/*'
  });
  return (
    <div className="form-control sc_basic_course_file_upload_section sc_lesson_file_upload_height" {...getRootProps()}>
      <div className="sc_file_upload_section">
        <img src={uploadFile} className="mb--05" />
        <span className="sc_upload_file_description text-center">
          Drag & drop files or <span className="sc_upload_file_colorful_text">browse</span>
          <span className="second_desc">Supports aac, mp3, m4a</span>
        </span>
      </div>

      <input {...getInputProps()} />
    </div>
  );
};


export default AudioFileUploader
