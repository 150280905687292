import React from "react";
import CoursesTypography from "../../../shared/Typography/CoursesTypography";
import SudentCoursesMainWrapper from "../../../components/students/wrapper/StudentCoursesMainWrapper";
import StudentCoursesComp from "../../../components/students/Courses";

const StudentCourses = () => {
  return (
    <SudentCoursesMainWrapper>
      <CoursesTypography text="Courses" subtext="Learn at your own pace with our well-structured online courses" />
      <StudentCoursesComp />
    </SudentCoursesMainWrapper>
  );
};

export default StudentCourses;
