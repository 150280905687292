import * as Yup from "yup";

const defaultValues = {
  name: "",
  type: "presentation",
  presentation_url: "",
  presentation_audio: [
    {
      page_number: "",
      audio_url: "",
    },
  ],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  type: Yup.string().default("presentation"),
  presentation_url: Yup.mixed().required("File is required"),

});

export { defaultValues, validationSchema };
