/* eslint-disable react/prop-types */
import React from "react";
import { useParams } from "react-router-dom";
import SearchBar from "../../../shared/searchBar/SearchBar";
import { useUpdateLearningPathStatusMutation } from "../../../services/admin/learningPathDetail/learningPathDetail";

const SearchAndButton = ({ searchText, setSearchText, setOpenAddModal, status, name }) => {
  const { id } = useParams();
  const [updateLearningPathStatus, { isLoading }] = useUpdateLearningPathStatusMutation();

  const handleStatus = () => {
    const data = { id: id, status: status == "draft" ? "published" : "draft" };
    updateLearningPathStatus(data);
  };
  return (
    <>
      <div className="student-search-form d-flex  justify-content-between   marginTop-0 ">
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
        <div className="d-flex">
          <button
            className={`btn btn-success ${status == "draft" ? " sc_success" : "sc_black"}  mr-1 text-capitalize`}
            style={{ height: "48px", backgroundColor: "#000 !important" }}
            type="button"
            onClick={handleStatus}
          >
            {/* {isLoading && "Loading..."} */}
            {status == "draft" ? "Publish" : "Unpublish"}
          </button>
          <div className="export-cvv-btn">
            <button className="btn-primary" onClick={() => setOpenAddModal(true)}>
              Add Courses
            </button>
          </div>
        </div>
      </div>

      <div className="sc_learning_path_divider marginBottom-10 text-uppercase">COURSES IN {name} </div>
    </>
  );
};

export default SearchAndButton;
