/* eslint-disable react/prop-types */
import React from "react";
import "./loader.css"

const Loader = ({Text1,Text2,loaderStyle}) => {
  return (
    <div className={`loader__wrapper bg-white d-flex flex-column align-items-center justify-content-center ${loaderStyle && "loader__wrapper__style"}`} style={{height:"50vh"}}>
      <div className="custom__loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <p className={`mb-0 ${loaderStyle && "para__style"}`}>{Text1 || "Loading"}</p>
      {
        Text2 && ( <div className={`${loaderStyle && "text__style"}`}>{Text2}</div>)
      }
    
    </div>
  );
};

export default Loader;
