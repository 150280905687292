import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/bootstrap.min.css";
//CSS & Bootstrap
import "./assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
//Font Awesome
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/myStyles/styles.css";

// custom css
import "./assets/css/mystyle.css";

import Store from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./AppRoute";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={Store}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AppRouter />
    </Provider>
  </BrowserRouter>,
);

// ReactDOM.render(
//   <Approuter/>,
// document.getElementById('root')
// );
