import React from "react";
import { courseAdded } from "../../../helpers/utils/image/imagePath";

const ShowSuccess = () => {
  return (
    <fieldset className="field-card" style={{ display: "block" }}>
      <div className="add-course-info">
        <div className="add-course-msg">
          <img src={courseAdded} />
          <h4>Your Course Added Successfully.</h4>
        </div>
      </div>
    </fieldset>
  );
};

export default ShowSuccess;
