import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { ChevronDown, ChevronUp } from "feather-icons-react/build/IconComponents";
import { setCurrentLesson } from "../../../../store/features/Student/student.slice";
import LessonCard from "./LessonCard";

const CourseContent = ({ data }) => {
  const [active, setActive] = useState(false);

  const dispatch = useDispatch();

  const { lesson } = useSelector((state) => state.studentSlice);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleLessonClick = (lesson) => {
    localStorage.setItem("last_lesson_id", lesson?.id);
    setCurrentIndex(lesson?.id);
    dispatch(setCurrentLesson(lesson));
  };

  useEffect(() => {
    if (lesson) {
      setActive(data?.id === lesson?.chapter_id);
      setCurrentIndex(lesson?.id);
    }
  }, [lesson]);

  const lessonOrLessons = data?.lessons?.length === 0 || data?.lessons?.length === 1 ? "Lesson" : "Lessons";
  return (
    <>
      <div
        className={`card content-sec mb-3 ${active && "card__title__box__style"}`}
        onClick={() => {
          setActive(!active);
          localStorage.setItem("last_chapter_id", data?.id);
        }}
      >
        <div className="card-body py-3 course__body__style">
          <div className="course-card ">
            <h6 className="cou-title d-flex align-items-center mb-0">
              <div className="pe-3 chapter__name">{data?.name}</div>
              <div className=" d-flex ms-auto">
                <p className="lessons__count m-0 me-4">
                  {data?.lessons?.length} {lessonOrLessons}
                </p>
                {active ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </div>
            </h6>
          </div>
        </div>
      </div>

      {active && (
        <div className={`card content-sec ${active && "card__margin"}`}>
          <div className="card-body pb-3 pt-2 ">
            <div className="course-card ">
              <div className="progress-stip">
                <div
                  className="progress-bar bg-success progress-bar-striped "
                  style={{ width: `${data?.progress}%` }}
                ></div>
              </div>
              <div className="student-percent d-flex border-bottom ">
                <p className="">{data?.duration}</p>
                <p className="ms-auto lessons__count ">{data?.progress}</p>
              </div>
              <ul>
                {data?.lessons?.map((lesson, index) => (
                  <LessonCard
                    key={index}
                    data={lesson}
                    currentIndex={currentIndex}
                    onClick={() => handleLessonClick(lesson)}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseContent;

CourseContent.propTypes = {
  data: PropTypes.object.isRequired,
};
