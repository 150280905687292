import React from "react";
import { Route, Routes } from "react-router-dom";
import ROUTES from "../helpers/constants/routes";

import Student from "./middleware/Student";
import StudentCourses from "../pages/student/courses";
import LearningPaths from "../pages/student/learningPaths";
import LerningPathCourses from "../pages/student/learningPathCourses";
import CourseDetail from "../pages/student/courseDetails";
import PropTypes from "prop-types";

const StudentRoute = ({setHideSideBar}) => {
  return (
    <Routes>
      <Route element={<Student />}>
        <Route path={ROUTES.STUDENT_COURSES} element={<StudentCourses />} />
        <Route path={ROUTES.STUDENT_LEARNING_PATH} element={<LearningPaths />} />
        <Route path={ROUTES.LEARNING_PATH_COURSES} element={<LerningPathCourses />} />
        <Route path={ROUTES.COURSES_DETAILS} element={<CourseDetail setHideSideBar={setHideSideBar} />} />
      </Route>
    </Routes>
  );
};

export default StudentRoute;

StudentRoute.propTypes = {
  setHideSideBar: PropTypes.func.isRequired,
};
