import { createSlice } from "@reduxjs/toolkit";
import { addLessonApi, createVideoId, deleteLesson } from "./lesson.api";

const initialState = {
  is_loading: {
    addLesson: false,
    videoId: false,
    uploadVideo: false,
    deleteLesson: false,
  },

  is_success: {
    addLesson: false,
    videoId: false,
    uploadVideo: false,
    deleteLesson: false,
  },
  bunny_video_id: "",
  lesson_data: {},
};

const lessonSlice = createSlice({
  name: "lessonSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ADD LESSON
    builder.addCase(addLessonApi.pending, (state) => {
      state.is_loading.addLesson = true;
      state.is_success.addLesson = false;
    });
    builder.addCase(addLessonApi.fulfilled, (state, { payload }) => {
      state.is_loading.addLesson = false;
      state.is_success.addLesson = true;
      state.lesson_data = payload.data;
      state.is_success.videoId = false;
      state.bunny_video_id = "";
    });
    builder.addCase(addLessonApi.rejected, (state) => {
      state.is_loading.addLesson = false;
      state.is_success.addLesson = false;
    });

    // CREATE VIDEO ID
    builder.addCase(createVideoId.pending, (state) => {
      state.is_loading.videoId = true;
      state.is_success.videoId = false;
    });
    builder.addCase(createVideoId.fulfilled, (state, { payload }) => {
      state.is_loading.videoId = false;
      state.is_success.videoId = true;
      state.bunny_video_id = payload.guid;
    });
    builder.addCase(createVideoId.rejected, (state) => {
      state.is_loading.videoId = false;
    });

    // DELETE LESSON
    builder.addCase(deleteLesson.pending, (state) => {
      state.is_loading.deleteLesson = true;
      state.is_success.deleteLesson = false;
    });
    builder.addCase(deleteLesson.fulfilled, (state, { payload }) => {
      state.is_loading.deleteLesson = false;
      state.is_success.deleteLesson = true;
    });
    builder.addCase(deleteLesson.rejected, (state) => {
      state.is_loading.deleteLesson = false;
    });
  },
});

export default lessonSlice.reducer;
