import React from "react";
import PropTypes from "prop-types";

const PasswordStrengthIndicator = ({ strength }) => {
  return (
    <div
      id="passwordStrength"
      style={{ display: "flex" }}
      className={`password-strength ${
        strength === "poor"
          ? "poor-active"
          : strength === "weak"
          ? "avg-active"
          : strength === "strong"
          ? "strong-active"
          : strength === "heavy"
          ? "heavy-active"
          : ""
      }`}
    >
      <span id="poor" className="active"></span>
      <span id="weak" className="active"></span>
      <span id="strong" className="active"></span>
      <span id="heavy" className="active"></span>
    </div>
  );
};

export default PasswordStrengthIndicator;

PasswordStrengthIndicator.propTypes = {
  strength: PropTypes.number.isRequired,
};
