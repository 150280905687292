import React from "react";
import PropTypes from "prop-types";

const InstructorMainWrapper = ({ children }) => {
  return (
    <div className="col-xl-9 col-lg-8 col-md-12">
      <div className="tak-instruct-group">
        <div className="row">
          <div className="col-md-12">{children} </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default InstructorMainWrapper;

InstructorMainWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
