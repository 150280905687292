/* eslint-disable react/prop-types */
import React from "react";
import SearchBar from "../../../shared/searchBar/SearchBar";

const SearchAndButton = ({ searchText, setSearchText, setOpenAddModal }) => {
  return (
    <>
      <div className="student-search-form d-flex  justify-content-between   marginTop-0 ">
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
        <div className="export-cvv-btn">
          <button className="btn btn-primary" onClick={() => setOpenAddModal(true)}>
            Add Learning Path
          </button>
        </div>
      </div>

      <div className="sc_learning_path_divider">LEARNING PATH NAME </div>
    </>
  );
};

export default SearchAndButton;
