/* eslint-disable react/prop-types */
import React, { useState } from "react";
import SearchBar from "../../../shared/searchBar/SearchBar";

const InstructorHeader = ({ setOpenModal, searchText, setSearchText }) => {
  return (
    <div className="settings-widget">
      <div className="settings-inner-blk p-0">
        <div className="sell-course-head student-meet-head comman-space border-0">
          <div className="tak-head d-flex align-items-center">
            <div>
              <h3>Instructors</h3>
              <p>See all the instructors.</p>
            </div>
          </div>
          <div className="student-search-form d-flex  justify-content-between  mt-5 marginBotom30">
            <SearchBar searchText={searchText} setSearchText={setSearchText} />
            <div className="export-cvv-btn">
              <button className="btn btn-primary" onClick={() => setOpenModal(true)}>
                Add Instructor
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorHeader;
