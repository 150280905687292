/* eslint-disable react/prop-types */
import React from "react";
import { Edit, Trash2 } from "react-feather";

const Lesson = ({
  lesson,
  openDeleteModal,
  setActiveLesson,
  chapter,
  setActiveChapter,
  setOpenLessonModal,
  setEditLesson,
}) => {
  const openModal = () => {
    setActiveLesson({ ...lesson, chapter_id: chapter?.id });
    openDeleteModal();
  };

  const handleEdit = () => {
    setActiveChapter(chapter);
    setOpenLessonModal(true);
    setActiveLesson({ ...lesson, chapter_id: chapter?.id });
    setEditLesson(true);
  };
  return (
    <div className="faq-grid">
      <div className="sc_add__lesson__section">
        <span className="lesson_name">{lesson?.name}</span>
        <div className="sc_lesson_button__section">
          <Edit size={18} className="edit__icon" onClick={handleEdit} />{" "}
          <Trash2 size={18} className="trash_icon" onClick={openModal} />
        </div>
      </div>
    </div>
  );
};

export default Lesson;
