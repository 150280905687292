import React from "react";
import { Link, useLocation } from "react-router-dom";
import ROUTES from "../../helpers/constants/routes";

const AdminNavLink = () => {
  const location = useLocation();
  return (
    <ul className="main-nav">
      <li className={`has-submenu ${location?.pathname == ROUTES.DASHBOARD ? "active" : ""}`}>
        <Link to={ROUTES.DASHBOARD}>My Courses</Link>
      </li>
      <li className={`has-submenu ${location?.pathname == ROUTES.INSTRUCTORS ? "active" : ""}`}>
        <Link to={ROUTES.INSTRUCTORS}>Instructors</Link>
      </li>
      <li className={`has-submenu ${location?.pathname == ROUTES.STUDENTS ? "active" : ""}`}>
        <Link to={ROUTES.STUDENTS}>Students</Link>
      </li>
    </ul>
  );
};

export default AdminNavLink;
