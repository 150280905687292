/* eslint-disable react/prop-types */
import React from "react";
import "./index.css";
import { selectedRadioButton, unSelectedRadioButton } from "../../helpers/utils/image/imagePath";

export const Radio = ({ label, value, setValue, description, className }) => {
  return (
    <div className={`${"sc_single_radio_item"} ${className}`} onClick={setValue}>
      <img src={value ? selectedRadioButton : unSelectedRadioButton} className="sc_selected_radio_icon" />
      <div className="sc_radio_label_section">
        <span className="sc_radio_button_label">{label}</span>
        <span className="sc_radio_button_description">{description}</span>
      </div>
    </div>
  );
};

const RadioButton = ({ children, className }) => {
  return (
    <div className="sc_radio_button_section">
      <label className="add-course-label mt-2">Course Publish Status</label>
      <div className={`${className}`}> {children}</div>
    </div>
  );
};

export default RadioButton;
