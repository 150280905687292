import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import ReactHtmlParser from "react-html-parser";
import { ChevronDown, ChevronUp } from "react-feather";
import PropTypes from "prop-types";

import CustomTextField from "../../../../../../shared/CKTextEditor/CustomTextField";
import SelectBox from "../../../../../../shared/Select";
import ChoiceForm from "./ChoiceForm";
import { quizQuestionTypeOptions } from "./data";

const QuestionCard = ({ index }) => {
  const [active, setActive] = useState(true);

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const questionValue = watch(`questions[${index}].question`);
  const questionType = watch(`questions[${index}].question_type`);

  return (
    <div className="question__section">
      <div className="question__wrapper" onClick={() => setActive(!active)}>
        <div className="question__container d-flex justify-content-between  ">
          <div className="question">
            <span>Question {index + 1}:</span> {ReactHtmlParser(questionValue || "No question")}
          </div>
          <span>{active ? <ChevronUp size={20} /> : <ChevronDown size={20} />}</span>
        </div>
      </div>

      {active && (
        <>
          <SelectBox
            label="Question Type"
            name={`questions[${index}].question_type`}
            control={control}
            options={quizQuestionTypeOptions}
            error={errors?.questions && errors?.questions[index]?.question_type}
          />

          <CustomTextField
            control={control}
            name={`questions[${index}].question`}
            placeholder="Write the question here.."
            error={errors?.questions && errors?.questions[index]?.question}
          />

          <ChoiceForm type={questionType} questionIndex={index} />
        </>
      )}
    </div>
  );
};

export default QuestionCard;

QuestionCard.propTypes = {
  index: PropTypes.number.isRequired,
};
