/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Topography from "./Topography";
import ModalFooter from "../../../../shared/modal/ModalFooter";
import Modal from "../../../../shared/modal/Modal";
import { PrimaryButton } from "../../../../shared/button/Button";
import TextField from "../../../../shared/inputs/TextField/TextField";
import {
  useCreateChapterMutation,
  useUpdateChapterMutation,
} from "../../../../services/admin/addCourse/chapter/chapter";

const AddChapterForm = ({ activeChapter, setActiveChapter, openChapternModal, setOpenChapternModal }) => {
  // const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const { id } = useParams();
  const [createChapter, { isLoading: isCreating, isSuccess: isCreated }] = useCreateChapterMutation();
  const [updateChapter, { isLoading: isUpading, isSuccess: isUpdated }] = useUpdateChapterMutation();

  const handleInput = (e) => {
    setName(e.target.value);
  };
  const handleForm = (e) => {
    e.preventDefault();
    const data = { name: name, course_id: id };

    if (!activeChapter) {
      createChapter(data);
    } else {
      data.id = activeChapter.id;
      updateChapter(data);
    }
  };
  const clearModal = () => {
    setName("");
    setActiveChapter();
  };

  useEffect(() => {
    if (isCreated || isUpdated) {
      clearModal();
      setOpenChapternModal(false);
    }
  }, [isCreated, isUpdated]);

  useEffect(() => {
    if (activeChapter) {
      setName(activeChapter.name);
    }
  }, [activeChapter]);

  return (
    <>
      <Topography setModalOpen={setOpenChapternModal} clearModal={clearModal} />
      <Modal
        title={activeChapter ? "Edit Chapter" : "Add Chapter"}
        modalOpen={openChapternModal}
        setModalOpen={setOpenChapternModal}
        onClear={clearModal}
      >
        <form className="mt-3" onSubmit={handleForm}>
          <TextField name="name" label="Chapter Name" value={name} onInput={handleInput} placeholder="" />
          <ModalFooter>
            <PrimaryButton text="Submit" type="submit" loading={isCreating || isUpading} />
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default AddChapterForm;
