export const options = [
  { label: "All", value: "all" },
  { label: "Draft", value: "draft" },
  { label: "Published", value: "published" },
];
export const style = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: "white",
    width: "100%",
    height: "40px",
    color: "black",
    minHeight: "40px",
    paddingLeft: "5px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    borderRadius: state.isSelected ? "0" : "10px",
    fontSize: "14px",
    "&:hover": {
      cursor: "pointer",
    },
    outline: "none",
  }),
  menu: (base) => ({ ...base, marginTop: "0px" }),
  menuList: (base) => ({ ...base, padding: "0" }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#FFDEDA" : "white",
    color: "black",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#FFDEDA",
      // #dddddd
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "black",
    transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
    transition: "250ms",
  }),
};
