/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Document, pdfjs } from "react-pdf";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import Modal from "../../../../../../shared/modal/Modal";
import TextField from "../../../../../../shared/inputs/TextField/TextField";
import ModalFooter from "../../../../../../shared/modal/ModalFooter";
import { PrimaryButton } from "../../../../../../shared/button/Button";
import FileUploader from "../../FileUploader";
import UploadProgress from "../../UploadProgress";
import Loader from "../../../../../../shared/Loader/Loader";
import PresentationSlides from "./PresentationSlides/PresentationSlides";
import { validationSchema } from "./data";
import ErrorText from "../../../../../../shared/inputs/errorText/ErrorText";
import { addLessonApi } from "../../../../../../store/features/admin/lesson/lesson.api";
import { useSelector } from "react-redux";

const AddPresentationLessonModal = ({ lesson, setLesson, chapter, modalOpen, setModalOpen }) => {
  const [fileInfo, setFileInfo] = useState({});
  const [pdfPages, setPdfPages] = useState([]);
  const [fileUploadedProgress, setFileUploadProgress] = useState(100);
  const [editMode, setMode] = useState(false);

  const [newData, setNewData] = useState([]);

  const dispatch = useDispatch();

  const { is_loading, is_success } = useSelector((state) => state.lessonSlice);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const clearFile = () => {
    setFileInfo({});
  };

  const clear = () => {
    reset();
    setFileInfo({});
    setModalOpen(false);
  };

  useEffect(() => {
    if (lesson) {
      setMode(!editMode);
      setNewData(lesson?.presentation_audio);
      reset({
        ...lesson,
      });
    }
    return () => setLesson(null);
  }, [lesson]);

  const submitForm = (values) => {
    const formData = new FormData();

    editMode && formData.append("id", values.id);
    formData.append("name", values.name);
    formData.append("type", "presentation");
    formData.append("presentation_url", values.presentation_url);
    formData.append("chapter_id", chapter?.id);
    newData.forEach((audio, index) => {
      formData.append("presentation_audio[" + index + "][page_number]", audio.page_number);
      if (audio.audio_url !== undefined && audio.audio_duration !== undefined) {
        formData.append("presentation_audio[" + index + "][audio_url]", audio.audio_url);
        formData.append("presentation_audio[" + index + "][audio_duration]", audio.audio_duration);
      }
    });
    dispatch(addLessonApi({ formData }));

    clear();
  };

  const handlePdfLoadSuccess = ({ numPages }) => {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push(i);
    }
    setPdfPages(pages);
    if (editMode && !fileInfo?.file) {
      return;
    } else {
      for (let i = 1; i <= numPages; i++) {
        setNewData((prev) => {
          return [
            ...prev,
            {
              page_number: i,
            },
          ];
        });
      }
    }
  };

  useEffect(() => {
    if (fileInfo?.file) {
      setValue("presentation_url", fileInfo?.file);
    }
  }, [fileInfo]);

  const currentFile = watch("presentation_url");

  useEffect(() => {
    if (newData.length === 0 && currentFile) {
      setFileInfo({});
      setValue("presentation_url", null);
    }
  }, [newData]);

  return (
    <Modal
      title={(editMode ? "Edit" : "New") + " Lesson"}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      onClear={clear}
    >
      <form className="mt-3" onSubmit={handleSubmit(submitForm)} encType="multipart/form-data">
        <TextField name="name" label="Title" register={register} error={errors.name} />

        {/* Files  Uploader  */}
        {!currentFile && (
          <div className="form-group">
            <label className="add-course-label">Upload File</label>
            <FileUploader setFileInfo={setFileInfo} />
            <ErrorText error={errors.presentation_url?.message} />
          </div>
        )}

        {/* Show File uploaded Progress bar  */}
        {/* {fileInfo.file && <UploadProgress progress={fileUploadedProgress} clearFile={clearFile} />} */}

        {/* Slide Generating Loading Screen  */}
        {/* <Loader loaderStyle={true} Text1="Processing you presentation" Text2="We are processing your request, this may take a few minutes." /> */}

        {/* Presentation slides  */}
        {/* <PresentationSlides /> */}

        {currentFile && (
          <Document file={currentFile} onLoadSuccess={handlePdfLoadSuccess} className={"document__custom__className"}>
            {newData?.map((page, index) => (
              <PresentationSlides key={index} data={page} setNewData={setNewData} />
            ))}
          </Document>
        )}

        <ModalFooter>
          <PrimaryButton text="Submit" type="submit" loading={is_loading?.addLesson} />
          {/* {fileInfo.file && <PrimaryButton text="Generate Slides" type="button" />} */}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddPresentationLessonModal;
