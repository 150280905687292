import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import CKTextEditor from "../../../../shared/CKTextEditor/CKTextEditor";

import TextField from "../../../../shared/inputs/TextField/TextField";
import Footer from "./Footer";
import ErrorText from "../../../../shared/inputs/errorText/ErrorText";
import CustomAsyncSelect from "./AsyncSelect";
import { formOptions } from "./helper";
import {
  useCreateCourseBasicInfoMutation,
  useGetCourseBasicInfoByIdQuery,
  useUpdateCourseBasicInfoMutation,
} from "../../../../services/admin/addCourse/basicInfo/course";
import ROUTES from "../../../../helpers/constants/routes";
import ImageUploader from "../../../../shared/ImageUploader/ImageUploader";

// eslint-disable-next-line react/prop-types
const BasicInfromation = ({ nextTab }) => {
  const [description, setDescription] = useState("");
  const [file, setFileInfo] = useState({});
  const [imageError, setImageError] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    isLoading,
    data: basicInfo,
    isSuccess: isFetched,
  } = useGetCourseBasicInfoByIdQuery(id, {
    skip: id === "course" ? true : false,
  });
  const [createCourseBasicInfo, { isLoading: isCreating, isSuccess: isCreated, data }] =
    useCreateCourseBasicInfoMutation();
  const [updateCourseBasicInfo, { isLoading: isUpading, isSuccess: isUpdated }] = useUpdateCourseBasicInfoMutation();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const handleError = () => {
    if (!file.base64Image) {
      setImageError("Image is Required");
    } else {
      setImageError("");
    }

    if (!file.base64Image) {
      return;
    }
  };

  const onSubmit = (values) => {
    handleError();
    if (Object.keys(file).length == 0) {
      return;
    }
    const value = {
      ...values,
      image: file.base64Image,
      description: description,
      instructor_id: values.instructor.value,
    };
    delete value?.instructor;

    if (id === "course") {
      // for creating new value
      createCourseBasicInfo(value);
    } else {
      value.id = basicInfo.data.id;
      updateCourseBasicInfo(value);
      //for updating data
    }
  };
  const onError = (error) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    if (isFetched) {
      const data = basicInfo.data;
      setValue("name", data.name);
      setFileInfo({ imageUrl: data.image, base64Image: data.image });
      if (data?.description) {
        setDescription(data.description);
      }
      setValue("name", data.name);
      setValue("instructor", { value: data.instructor.id, label: data.instructor.name });
    }
  }, [isFetched]);

  useEffect(() => {
    if (isCreated) {
      navigate(`${ROUTES.ADD_NEW_COURSE}/${data.data.id}`);
      nextTab();
    }
    if (isUpdated) {
      nextTab();
    }
  }, [isCreated, isUpdated]);

  return (
    <fieldset id="first">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="add-course-info">
          <div className="add-course-inner-header">
            <h4>Basic Information</h4>
          </div>
          <div className="add-course-form">
            <TextField
              name="name"
              label="Course Title"
              register={register}
              error={errors?.name}
              placeholder="Course Title"
            />
            <div className="form-group">
              <label className="add-course-label">Course Cover Image</label>
              <ImageUploader file={file} setFileInfo={setFileInfo} />
              <ErrorText error={imageError} />
            </div>
            <div className="form-group mb-0">
              <label className="add-course-label">Course Description</label>
              <div id="editor">
                <CKTextEditor data={description} setData={setDescription} />
              </div>
            </div>
            <CustomAsyncSelect
              name="instructor"
              label="Instructor"
              register={register}
              error={errors?.instructor}
              control={control}
            />
          </div>
          <Footer id={id} nextTab={nextTab} loading={isCreating || isUpading} />
        </div>
      </form>
    </fieldset>
  );
};

export default BasicInfromation;
