/* eslint-disable react/prop-types */
import React from "react";
import "./index.css";
import { X } from "react-feather";

const ScModal = ({ modalOpen, setModalOpen, children }) => {
  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <div className={`sc__modal ${modalOpen ? "active" : ""} `}>
      <div className={`sc__modal__content__wrapper`}>
        <X size={20} onClick={closeModal} className="sc__modal__close__icon" />
        {children}
      </div>
    </div>
  );
};

export default ScModal;
