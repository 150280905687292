/* eslint-disable react/prop-types */
import React from 'react'
const ScModalBody = ({children}) => {
  return (
    <div>
       <div className="sc__modal__body">{children}</div>
    </div>
  )
}

export default ScModalBody
