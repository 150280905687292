import React from "react";
import { useState } from "react";
import { Copy, Eye, EyeOff, RefreshCcw } from "react-feather";
import {
  useGenerateApiKeyMutation,
  useGetSiteSettingsQuery,
  useUpdatwebHookUrlMutation,
} from "../../../services/admin/siteSetting/siteSetting";
import { useEffect } from "react";

const IntigrationSettingForm = () => {
  const { data: siteInfo, isSuccess: isDataFetched } = useGetSiteSettingsQuery();
  const [updatwebHookUrl, { isLoading: isUpdating }] = useUpdatwebHookUrlMutation();
  const [generateApiKey, { isLoading: isGenerating }] = useGenerateApiKeyMutation();

  const [passwordType, setPasswordType] = useState("password");
  const [apiKeyInput, setApiKeyInput] = useState("");
  const [hookUrl, setHookUrl] = useState("");
  const handlePasswordChange = (evnt) => {
    setApiKeyInput(evnt.target.value);
  };

  useEffect(() => {
    if (siteInfo?.data && isDataFetched) {
      setApiKeyInput(siteInfo.data.api_key);
      setHookUrl(siteInfo.data.webhook_url);
    }
  }, [siteInfo, isDataFetched]);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const updateHook = () => {
    const data = { url: hookUrl };
    updatwebHookUrl(data);
  };

  return (
    <>
      <form>
        <div className="row">
          <div className="personal-info-head border-top mt-3">
            <h4 className="mt-3">Integration Settings</h4>
            <p>
              Configure webhook URLs and API keys for seamless communication and data exchange with external services.
            </p>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label className="form-control-label">API Key</label>
              <div className="pass-group">
                <input
                  type={passwordType}
                  onChange={handlePasswordChange}
                  value={apiKeyInput}
                  name="api_key"
                  className="form-control"
                  placeholder="API Key"
                  style={{ paddingRight: "100px" }}
                  readOnly
                />
                <div className="api__setting__action__icons">
                  <span className="icon__span" onClick={togglePassword}>
                    {passwordType === "password" ? <Eye size={16} /> : <EyeOff size={16} />}
                  </span>
                  <span className=" icon__span" onClick={() => navigator.clipboard.writeText(apiKeyInput)}>
                    <Copy size={16} />
                  </span>
                  <span className={`icon__span ${isGenerating && "spin"} `}>
                    <RefreshCcw size={16} onClick={() => generateApiKey()} />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="form-group">
              <label className="form-control-label">Webhook URL</label>
              <div className="pass-group">
                <input
                  type="url"
                  name="webhook_url"
                  className="form-control "
                  placeholder="https://scholarsai.com/bunny-webhook"
                  style={{ paddingRight: "110px" }}
                  value={hookUrl}
                  onChange={(e) => setHookUrl(e.target.value)}
                />
                <div className="api__setting__save__btn">
                  <button type="button" disabled={isUpdating} onClick={updateHook}>
                    {!isUpdating ? "Save" : "loading..."}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default IntigrationSettingForm;
