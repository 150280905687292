/* eslint-disable react/prop-types */
import React from "react";

const Footer = ({ prevTab2, loading }) => {
  return (
    <div className="widget-btn">
      <button className="btn btn-black prev_btn" to="#" onClick={prevTab2}>
        Previous
      </button>
      <button className="btn btn-info-light next_btn" type="submit">
        {!loading ? "Continue" : "loading..."}
      </button>
    </div>
  );
};

export default Footer;
