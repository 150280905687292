import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import PropTypes from "prop-types";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { SizeMe } from "react-sizeme";

const PdfViewer = ({ title, pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleDownload = () => {
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "document.pdf";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  return (
    <div className="card content-sec">
      <div className="card-body ">
        <div className="row">
          <div className="col-sm-6">
            <h5 className="video__introduction">{title}</h5>
          </div>
        </div>
        <div style={{ height: "820px", overflowY: "scroll" }}>
          <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (_, index) => (
              <>
                <Page key={index} pageNumber={index + 1} />
                <p className="d-flex align-items-center justify-content-center my-2">
                  Page {index + 1} of {numPages}
                </p>
              </>
            ))}
          </Document>
        </div>

        {/* {numPages && (
          <div>
            <p className="d-flex align-items-center justify-content-center my-2">
              Page {pageNumber} of {numPages}
            </p>
            <nav aria-label="pdf-pagination" className="d-flex align-items-center justify-content-center">
              <ul className="pagination pagination-sm">
                <li className={`page-item  ${pageNumber === 1 ? "disabled" : ""}`} style={{ cursor: "pointer" }}>
                  <button className="page-link" onClick={() => setPageNumber(pageNumber - 1)}>
                    Previous
                  </button>
                </li>
                <li className={`page-item  ${pageNumber === numPages ? "disabled" : ""}`} style={{ cursor: "pointer" }}>
                  <button className="page-link" onClick={() => setPageNumber(pageNumber + 1)}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )} */}

        <div className="start-leason hoverBlue d-flex align-items-center  justify-content-end mt-4 ">
          <button type="button" className="btn btn-primary  download__button" onClick={handleDownload}>
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
PdfViewer.propTypes = {
  title: PropTypes.string.isRequired,
  pdfUrl: PropTypes.string.isRequired,
};
