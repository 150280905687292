/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "../../../shared/modal/Modal";
import TextField from "../../../shared/inputs/TextField/TextField";
import ModalFooter from "../../../shared/modal/ModalFooter";
import { PrimaryButton } from "../../../shared/button/Button";
import { formOptions } from "./helper";
import TextArea from "../../../shared/inputs/TextArea/TextArea";
import ImageUploader from "../../../shared/ImageUploader/ImageUploader";
import ErrorText from "../../../shared/inputs/errorText/ErrorText";
import { useCreateLearningPathMutation } from "../../../services/admin/learningPath/learningPath";

const From = ({ openAddModal, setOpenAddModal }) => {
  const [file, setFileInfo] = useState({});
  const [imageError, setImageError] = useState("");
  const [createLearningPath, { isLoading: isCreating, isSuccess: isCreated }] = useCreateLearningPathMutation();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm(formOptions);
  const clearFrom = () => {
    reset();
    setFileInfo({});
    setImageError("");
  };

  const handleError = () => {
    if (!file.base64Image) {
      setImageError("Image is Required");
    } else {
      setImageError("");
    }
  };
  const onSubmit = (values) => {
    handleError();

    if (Object.keys(file).length == 0) {
      return;
    }
    values.status = "draft";
    values.image = file.base64Image;
    createLearningPath(values);
  };
  const onError = (error) => {
    console.log("ERROR:::", error);
  };
  useEffect(() => {
    if (isCreated) {
      clearFrom();
      setOpenAddModal(false);
    }
  }, [isCreated]);

  return (
    <Modal title="Add Learning Path" modalOpen={openAddModal} setModalOpen={setOpenAddModal} onClear={clearFrom}>
      <form className="mt-3" encType="multipart/form-data" onSubmit={handleSubmit(onSubmit, onError)}>
        <TextField
          name="name"
          type="text"
          placeholder=""
          label="Learning Path Name"
          register={register}
          error={errors?.name}
        />

        <div className="form-group">
          <label className="add-course-label">Course Cover Image</label>
          <ImageUploader file={file} setFileInfo={setFileInfo} />
          <ErrorText error={imageError} />
        </div>

        <TextArea
          name="description"
          type="text"
          placeholder=""
          label="Description"
          register={register}
          error={errors?.description}
        />
        <ModalFooter>
          <PrimaryButton text="Submit" type="submit" loading={isCreating} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default From;
