/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Modal from "../../../../../../shared/modal/Modal";
import TextField from "../../../../../../shared/inputs/TextField/TextField";
import ModalFooter from "../../../../../../shared/modal/ModalFooter";
import { PrimaryButton } from "../../../../../../shared/button/Button";
import VideoUploader from "../../VideoUploader";
import SelectMediaTypeDropDown from "./SelectMediaTypeDropDown";
import {
  addLessonApi,
  createVideoId,
  uploadVideoToBunny,
} from "../../../../../../store/features/admin/lesson/lesson.api";
import CustomTextField from "../../../../../../shared/CKTextEditor/CustomTextField";
import UploadProgress from "../../UploadProgress";
import SelectBox from "../../../../../../shared/Select";
import ErrorText from "../../../../../../shared/inputs/errorText/ErrorText";

const validationSchema = yup.object().shape({
  name: yup.string().required().label("Title"),
  type: yup.string().required("Type is required").label("Type"),
  video_url: yup.string().when(["type"], {
    is: "video",
    then: (schema) => schema.required("Video url is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  other_video_url: yup.string().when(["type"], {
    is: "other_video",
    then: (schema) => schema.required("Video url is required"),
    otherwise: (schema) => schema.nullable(),
  }),
});

const AddVideoLessonModal = ({ lesson, setLesson, chapter, modalOpen, setModalOpen }) => {
  const dispatch = useDispatch();
  const { is_loading, is_success, bunny_video_id } = useSelector((state) => state.lessonSlice);

  const [editMode, setMode] = useState(false);
  const [selectedMediaType, setSelectedMediaType] = useState("Select your media type");
  const [uploadMediaType, setUploadMediaType] = useState(false);
  const [VideoUrlMediaType, setVideoUrlMeidaType] = useState(false);
  const [youtubeVideoUrl, setYoutubeVideoUrl] = useState("");
  const [videoFileInfo, setVideoFileInfo] = useState({});
  const [selectedVideoFileUrl, setSelectedVideoFileUrl] = useState("");
  const [videoUploadedProgress, setVideoUploadedProgress] = useState(0);
  const [tsuInstance, setTsuInstance] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [videoStatus, setVideoStatus] = useState({ loading: false, success: false });

  useEffect(() => {
    if (videoUploadedProgress > 0 && videoUploadedProgress < 100) {
      setIsUploading(true);
    } else {
      setIsUploading(false);
    }
  }, [videoUploadedProgress]);

  const videoRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    watch,
    trigger,
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      text: "",
      other_video_url: "",
      video_url: "",
      type: "",
    },
  });

  const clear = () => {
    setModalOpen(false);
  };

  const clearVideo = () => {
    setVideoFileInfo({});
  };

  const clearForm = () => {
    reset();
    setVideoFileInfo({});
    setSelectedVideoFileUrl("");
    videoRef?.current?.load();
  };

  useEffect(() => {
    if (lesson) {
      setMode(!editMode);
      setSelectedVideoFileUrl(lesson?.video_url);
      lesson?.type === "other_video" ? setVideoUrlMeidaType(true) : setUploadMediaType(true);
      reset(lesson);
    }
    return () => setLesson(null);
  }, [lesson]);

  useEffect(() => {
    if (videoFileInfo?.file) {
      dispatch(createVideoId({ title: getValues("name") }));
    }
  }, [videoFileInfo]);

  useEffect(() => {
    if (is_success.videoId) {
      dispatch(
        uploadVideoToBunny({
          data: videoFileInfo.file,
          videoId: bunny_video_id,
          setTsuInstance: setTsuInstance,
          setVideoStatus: setVideoStatus,
          videoStatus: videoStatus,
          setVideoUploadedProgress: setVideoUploadedProgress,
        }),
      );
    }
  }, [is_success.videoId]);

  const submitForm = async (values) => {
    const formData = new FormData();

    editMode && formData.append("id", values.id);
    formData.append("name", values.name);
    formData.append("text", values.text);
    formData.append("chapter_id", chapter?.id);
    formData.append("type", values.type);

    values.type === "video"
      ? formData.append("video_url", values.video_url)
      : formData.append("other_video_url", values.other_video_url);

    dispatch(addLessonApi({ formData }));

    clearForm();
    setModalOpen(false);
  };

  const clearFile = () => {
    setVideoFileInfo({});
    setSelectedVideoFileUrl("");
  };

  const selectedType = watch("type");

  useEffect(() => {
    if (selectedType && bunny_video_id) {
      setValue("video_url", bunny_video_id);
      trigger("video_url");
    }
  }, [selectedType, bunny_video_id]);

  return (
    <Modal
      title={(editMode ? "Edit" : "New") + " Lesson"}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      onClear={clear}
    >
      <form className="mt-3" onSubmit={handleSubmit(submitForm)} encType="multipart/form-data">
        <TextField name="name" label="Title" register={register} error={errors.name} />

        <SelectBox
          label="Type"
          name="type"
          control={control}
          options={[
            { label: "Upload Video", value: "video" },
            { label: "Import from Youtube", value: "other_video" },
          ]}
          error={errors.type}
        />

        {selectedType === "video" && (
          <div className="form-group">
            <label className="add-course-label">Upload Video File</label>
            <VideoUploader
              setVideoFileInfo={setVideoFileInfo}
              clearVideo={clearVideo}
              videoRef={videoRef}
              selectedVideoFileUrl={selectedVideoFileUrl}
              setSelectedVideoFileUrl={setSelectedVideoFileUrl}
            />
            <ErrorText error={errors.video_url?.message} />
          </div>
        )}
        {videoFileInfo.file && <UploadProgress progress={videoUploadedProgress} clearFile={clearFile} />}
        {selectedType === "other_video" && (
          <TextField
            type="url"
            name="other_video_url"
            label="Video URL"
            placeholder="Paste your Video URL"
            register={register}
            error={errors.other_video_url}
          />
        )}
        <CustomTextField control={control} name="text" label="Content" error={errors.text} />
        <ModalFooter>
          <PrimaryButton
            text="Submit"
            type="submit"
            loading={is_loading?.addLesson || isUploading}
            disabled={isUploading}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddVideoLessonModal;
