/* eslint-disable react/prop-types */
import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CKTextEditor = ({ data, setData }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        // removePlugins: ["Heading", "Link"],
        toolbar: ["Heading", "bold", "italic", "bulletedList", "numberedList", "blockQuote"],
      }}
      // {...rest}
      //   onReady={(editor) => {
      //     // You can store the "editor" and use when it is needed.

      //   }}
      data={data}
      onChange={(event, editor) => {
        const data = editor.getData();
        setData(data);
        // console.log({ event, editor, data });
      }}
      // onBlur={(event, editor) => {
      //   console.log("Blur.", editor);
      // }}
      // onFocus={(event, editor) => {
      //   console.log("Focus.", editor);
      // }}
    />
  );
};

export default CKTextEditor;
