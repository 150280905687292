/* eslint-disable react/prop-types */
import React from "react";

const Wrapper = ({ children }) => {
  return (
    <div className="container">
      <div className="student-widget-group p-0 mt-4">
        <div className="row">
          <div className="col-lg-12 "> {children}</div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default Wrapper;
