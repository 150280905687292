import React, { useEffect, useState } from "react";
import { hasMixed, hasNumber, hasSpecial, strengthColor } from "./helper";
import Message from "./Message";
import TermsAndService from "./TermsAndService";
import PasswordStrengthIndicator from "./PasswordStrengthIndicator";
import { useAppDispatch } from "../../../store/store";
import { SingnUpUser } from "../../../store/features/Auth/auth.api";

const SingupForm = () => {
  const [eye, seteye] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationError, setValidationError] = useState("");
  const [strength, setStrength] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();

  const onEyeClick = () => {
    seteye(!eye);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const validateTerms = () => {
    if (!agreedToTerms) {
      setError("Please agree to the terms and conditions.");
      return false;
    }
    setError("");
    return true;
  };

  const validatePassword = (value) => {
    if (!value) {
      setValidationError(1);
    } else if (value.length < 8) {
      setValidationError(2);
    } else if (!/[0-9]/.test(value)) {
      setValidationError(3);
    } else if (!/[!@#$%^&*()]/.test(value)) {
      setValidationError(4);
    } else {
      setValidationError(5);
    }
  };

  const strengthIndicator = (value) => {
    let strengths = 0;

    if (value.length >= 8) strengths = 1;
    if (hasNumber(value) && value.length >= 8) strengths = 2;
    if (hasSpecial(value) && value.length >= 8 && hasNumber(value)) strengths = 3;
    if (hasMixed(value) && hasSpecial(value) && value.length >= 8 && hasNumber(value)) strengths = 3;
    return strengths;
  };

  useEffect(() => {
    if (password) {
      if (password !== "") {
        let strength = strengthIndicator(password);
        let color = strengthColor(strength);
        setStrength(color);
      } else {
        setStrength("");
      }
    }
  }, [password]);

  const clearForm = () => {
    let valid = 1;
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setAgreedToTerms(false);
    setStrength("");
    setValidationError(valid);
  };

  const handleFrom = (e) => {
    e.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      password_confirmation: password,
    };
    if (validateTerms()) {
      dispatch(SingnUpUser(data));
      clearForm();
    }
  };

  return (
    <form onSubmit={handleFrom}>
      <div className="form-group">
        <label className="form-control-label">First Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter your Full Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label className="form-control-label">Last Name</label>

        <input
          type="text"
          className="form-control"
          placeholder="Enter your Full Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label className="form-control-label">Email</label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label className="form-control-label">Password</label>
        <div className="pass-group" id="passwordInput">
          <input
            className="form-control pass-input"
            placeholder="Enter your password"
            type={eye ? "password" : "text"}
            onChange={handlePasswordChange}
            value={password}
          />
          <span onClick={onEyeClick} className={`fa toggle-password feather-eye" ${eye ? "fa-eye" : "fa-eye-slash"}`} />
          <span className="toggle-password feather-eye"></span>
          <span className="pass-checked">
            <i className="feather-check"></i>
          </span>
        </div>
        <PasswordStrengthIndicator strength={strength} />
        <div id="passwordInfo">
          <Message validationError={validationError} />
        </div>
      </div>

      <TermsAndService agreedToTerms={agreedToTerms} setAgreedToTerms={setAgreedToTerms} setError={setError} />
      <div style={{ color: "#f66962", fontSize: "14px",marginTop:"-20px" }}>{error}</div>
      <div className="d-grid mt-3">
        <button className="btn btn-primary btn-start" type="submit">
          Create Account
        </button>
      </div>
    </form>
  );
};

export default SingupForm;
