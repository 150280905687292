/* eslint-disable react/prop-types */
import React from "react";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import ErrorText from "../../../../shared/inputs/errorText/ErrorText";
import axiosInstance from "../../../../settings/axiosInstance";
import { BASE_URL } from "../../../../settings/urlConfig";
import { customStyles } from "./helper";

const CustomAsyncSelect = ({ name, label, control, register, error }) => {
  const errorMessage = error?.message;

  const loadOptions = async (input) => {
    const res = await axiosInstance.get(`${BASE_URL}/instructors?search=${input}&page=1`);

    const arr = res.data.data.data.map((e) => {
      return { label: e.name, value: e.id };
    });
    return arr;
  };

  return (
    <div className="form-group">
      <label className="add-course-label" htmlFor={name}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <AsyncSelect
              register={register}
              placeholder="Select Instructor"
              styles={customStyles}
              cacheOptions
              defaultOptions
              value={{ value: 1, label: "black" }}
              loadOptions={loadOptions}
              {...field}
            />
            <ErrorText error={errorMessage} />
          </>
        )}
      />
    </div>
  );
};

export default CustomAsyncSelect;
