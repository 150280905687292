import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import VideoCard from "./Contents/VideoCard";
import CourseOverviewSection from "./Contents/CourseOverviewSection";
import IncludesSection from "./Contents/IncludesSection";
import PdfViewer from "./Contents/PdfViewer";
import OtherUrlViewer from "./Contents/OtherUrlViewer";
import CourseContent from "./Contents/CourseContent";
import { setCurrentCourse, setCurrentLesson } from "../../../store/features/Student/student.slice";
import localDb from "../../../helpers/utils/localDb/localDb";
import { useCreateUpdateLessonEnrollmentMutation } from "../../../services/admin/student/student";

const CourseDetailsContent = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.authSlice);

  const { course, lesson } = useSelector((state) => state.studentSlice);

  const [changeLessonEnrollment, { isLoading: isEnrolling }] = useCreateUpdateLessonEnrollmentMutation();

  const handleNext = (chapterIndex, lessonIndex) => {
    const currentChapter = course?.chapters[chapterIndex];

    if (lessonIndex < currentChapter?.lessons?.length - 1) {
      const nextLesson = currentChapter?.lessons[lessonIndex + 1];
      localDb.save("last_lesson_id", nextLesson?.id);
      dispatch(setCurrentLesson(nextLesson));
    } else {
      const nextChapter = course?.chapters[chapterIndex + 1];
      if (nextChapter) {
        const nextLesson = nextChapter?.lessons[0];
        //update last chapter and lesson
        localDb.save("last_chapter_id", nextChapter?.id);
        localDb.save("last_lesson_id", nextLesson?.id);
        //save next lesson
        dispatch(setCurrentLesson(nextLesson));
      }
    }
  };

  const handleVideoEnd = () => {
    let newCourse = JSON.parse(JSON.stringify(course));
    const chapterId = localDb.fetch("last_chapter_id");
    const chapterIndex = newCourse?.chapters?.findIndex((item) => item.id === chapterId);
    const lessonIndex = newCourse?.chapters[chapterIndex]?.lessons?.findIndex((item) => item.id === lesson?.id);

    //mark Lesson Complete and play next lesson video
    if (lesson?.enrolled === false) {
      changeLessonEnrollment({
        course_id: course?.id,
        user_id: user?.id,
        lesson_id: lesson?.id,
        completed: true,
      })
        .unwrap()
        .then(({ data }) => {
          newCourse.chapters[chapterIndex].progress = data?.chapter_progress;
          newCourse.chapters[chapterIndex].lessons[lessonIndex].enrolled = data?.enrolled;
          dispatch(setCurrentCourse(newCourse));
          handleNext(chapterIndex, lessonIndex);
        });
    } else {
      handleNext(chapterIndex, lessonIndex);
    }
  };

  const displayLessonDataFrame = () => {
    if (lesson?.video_url) {
      return <VideoCard videoUrl={lesson?.video_url} title={lesson?.name} onVideoEnd={handleVideoEnd} />;
    } else if (lesson?.attachment_url) {
      return <PdfViewer title={lesson?.name} pdfUrl={lesson?.attachment_url} />;
    } else if (lesson?.other_video_url) {
      return <OtherUrlViewer videoUrl={lesson?.other_video_url} title={lesson?.name} />;
    }
  };

  return (
    <section className="page-content course-sec">
      <div className="container">
        <div className="row" id="1">
          <div className="col-lg-8">
            {/* Overview */}
            <CourseOverviewSection />

            {/* /Overview */}
          </div>
          <div className="col-lg-4">
            <IncludesSection />
          </div>
        </div>
        <div className="row" id="2">
          <div className="col-lg-4">
            <div className="row ">
              <div className="">
                <h5 className="subs-title mb-0  p-4">Course Content</h5>
              </div>
            </div>

            {course?.chapters?.map((item) => (
              <CourseContent key={item?.id} data={item} />
            ))}
          </div>
          {lesson && (
            <div className="col-lg-8">
              <div>{displayLessonDataFrame()}</div>
              <div className="d-flex justify-content-end">
                <button
                  onClick={handleVideoEnd}
                  className="btn"
                  style={{
                    background: "#392C7D",
                    borderRadius: "5px",
                    border: "1px solid #392C7D",
                    color: "#FFF",
                    fontSize: "14px",
                  }}
                  disabled={isEnrolling}
                >
                  {isEnrolling ? "loading.." : "Complete and continue"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CourseDetailsContent;
