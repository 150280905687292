import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";

export const validationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_VALIDATION("Learning Path")),
  description: yup.string().required(REQUIRED_VALIDATION("Description")),
});
export const formOptions = {
  resolver: yupResolver(validationSchema),
  mode: "onTouched",
  reValidateMode: "onChange",
};
