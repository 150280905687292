import React from "react";
import PropTypes from "prop-types";

import getYoutubeVideoId from "../../../../helpers/utils/getYoutubeVideoId";

const OtherUrlViewer = ({ title, videoUrl }) => {
  return (
    <div className="card content-sec">
      <div className="card-body">
        <div className="row">
          <div className="col-sm-6">
            <h5 className="video__introduction">{title}</h5>
          </div>
        </div>

        <div style={{ position: "relative", paddingTop: "56.25%" }}>
          <iframe
            src={`https://www.youtube.com/embed/${getYoutubeVideoId(videoUrl)}`}
            loading="lazy"
            style={{ border: "none", position: "absolute", top: 0, height: "100%", width: "100%" }}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default OtherUrlViewer;

OtherUrlViewer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
