/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import Modal from "../../../../../../shared/modal/Modal";
import TextField from "../../../../../../shared/inputs/TextField/TextField";
import ModalFooter from "../../../../../../shared/modal/ModalFooter";
import { PrimaryButton } from "../../../../../../shared/button/Button";
import FileUploader from "../../FileUploader";
import UploadProgress from "../../UploadProgress";
import { addLessonApi } from "../../../../../../store/features/admin/lesson/lesson.api";
import CustomTextField from "../../../../../../shared/CKTextEditor/CustomTextField";
import ErrorText from "../../../../../../shared/inputs/errorText/ErrorText";
import ShowPdf from "./ShowPdf";

const validationSchema = yup.object().shape({
  name: yup.string().required().label("Title"),
  attachment_url: yup.mixed().required().label("File"),
});

const AddPdfLessonModal = ({ lesson, setLesson, chapter, modalOpen, setModalOpen }) => {
  const [fileInfo, setFileInfo] = useState({});
  const [editMode, setMode] = useState(false);
  const [fileUploadedProgress, setFileUploadedProgress] = useState(100);

  const dispatch = useDispatch();

  const { is_loading } = useSelector((state) => state.lessonSlice);

  useEffect(() => {
    if (fileInfo.file) {
      setValue("attachment_url", fileInfo.file);
    }
  }, [fileInfo]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (lesson) {
      setMode(!editMode);
      reset(lesson);
    }
    return () => setLesson(null);
  }, [lesson]);

  const clear = () => {
    setModalOpen(false);
  };

  const clearFile = () => {
    setFileInfo({});
  };
  const clearForm = () => {
    reset();
    setFileInfo({});
  };

  const currentFile = watch("attachment_url");

  const submitForm = (values) => {
    const formData = new FormData();

    editMode && formData.append("id", values?.id);

    formData.append("type", "file");
    formData.append("chapter_id", chapter?.id);
    formData.append("name", values.name);
    values.text && formData.append("text", values.text);
    formData.append("attachment_url", values.attachment_url);

    dispatch(addLessonApi({ formData }));

    clearForm();
    setModalOpen(false);
  };

  const handleRemove = () => setValue("attachment_url", "");

  return (
    <Modal
      title={(editMode ? "Edit" : "New") + " Lesson"}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      onClear={clear}
    >
      <form className="mt-3" onSubmit={handleSubmit(submitForm)} encType="multipart/form-data">
        <TextField name="name" label="Title" register={register} error={errors.name} />
        {!currentFile && !fileInfo.file && (
          <div className="form-group">
            <label className="add-course-label">Upload PDF</label>
            <FileUploader setFileInfo={setFileInfo} />
            {errors.attachment_url && <ErrorText error={errors.attachment_url.message} />}
          </div>
        )}

        {currentFile && !fileInfo.file && <ShowPdf url={currentFile} handleRemove={handleRemove} />}

        {fileInfo.file && <UploadProgress progress={fileUploadedProgress} clearFile={clearFile} />}

        <CustomTextField control={control} name="text" label="Content" error={errors.text} />
        <ModalFooter>
          <PrimaryButton text="Submit" type="submit" loading={is_loading?.addLesson} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddPdfLessonModal;
