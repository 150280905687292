import React from "react";
import { Search } from "react-feather";
import PropTypes from "prop-types";
import "./index.css";

const FullWithSearch = ({ searchText, setSearchText, className, placeholder }) => {
  return (
    <div className="search-group sc_full_search_div">
      <Search className="searchFeather search_icon" size={16} />
      <input
        type="text"
        className={`form-control ${className}`}
        placeholder={placeholder || "search"}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
    </div>
  );
};

export default FullWithSearch;
FullWithSearch.propTypes = {
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
