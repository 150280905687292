import React, { useState } from "react";
import PropTypes from "prop-types";

import SearchBar from "../../../shared/searchBar/SearchBar";

const AssignmentsHeader = ({ search, setSearch }) => {
  return (
    <div className="settings-widget">
      <div className="settings-inner-blk p-0">
        <div className="sell-course-head student-meet-head comman-space border-0">
          <div className="tak-head d-flex align-items-center">
            <div>
              <h3>Assignments</h3>
              <p>Meet people taking your courses.</p>
            </div>
          </div>
          <div className="student-search-form d-flex  justify-content-between  mt-5 marginBotom30">
            <SearchBar searchText={search} setSearchText={setSearch} placeholder="Search" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentsHeader;

AssignmentsHeader.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
};
