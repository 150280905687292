import React, {useState} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { SizeMe } from "react-sizeme";
import { AdminLayout } from "../../layout/admin/AdminLayout";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const ReactPdf = () => {
    //pdf view
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return <AdminLayout>
    <div className="col-xl-6 col-lg-5 col-md-12">
    <SizeMe>
            {({ size }) => (                      
                <Document
                    file={"https://theagilecoach-uploads.s3.us-east-2.amazonaws.com/0gW1Wzw4CB6ptdYGWhbL.pdf"}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page
                        pageNumber={pageNumber}
                        width={size.width ? size.width : 1}
                    />
                </Document>
            )}
        </SizeMe>

        {numPages && (
            <>
                <p className="d-flex align-items-center justify-content-center">
                    Page {pageNumber} of {numPages}
                </p>
                <nav
                    aria-label="pdf-pagination"
                    className="d-flex align-items-center justify-content-center"
                >
                    <ul className="pagination pagination-sm">
                        <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() =>
                                    setPageNumber(pageNumber - 1)
                                }
                            >
                                Previous
                            </button>
                        </li>
                        <li className={`page-item ${pageNumber === numPages ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() =>
                                    setPageNumber(pageNumber + 1)
                                }
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </>
        )}
    </div>
  </AdminLayout>;
};

export default ReactPdf;
