import React from "react";
import ROUTES from "../../helpers/constants/routes";
import { Book, BookOpen } from "react-feather";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const StudentSidebarLinks = ({ activeMenu }) => {
  return (
    <ul>
      <li className={activeMenu === ROUTES.STUDENT_COURSES ? "nav-item active" : "nav-item"}>
        <Link to={ROUTES.STUDENT_COURSES} className="nav-link">
          <Book size={20} />
          Courses
        </Link>
      </li>

      <li className={activeMenu === ROUTES.STUDENT_LEARNING_PATH ? "nav-item active" : "nav-item"}>
        <Link to={ROUTES.STUDENT_LEARNING_PATH} className="nav-link">
        <BookOpen size={20} />
          Learning Paths
        </Link>
      </li>
      {/*  */}
    </ul>
  );
};

export default StudentSidebarLinks;

StudentSidebarLinks.propTypes = {
  activeMenu: PropTypes.string.isRequired,
};
