import React from "react";
import PropTypes from "prop-types";
import LeftSideImageSection from "./LeftSideImageSection";
import LogoAndBackSection from "./LogoAndBackSection";

const AuthLayout = ({ children, description }) => {
  return (
    <div className="main-wrapper log-wrap">
      <div className="row">
        <LeftSideImageSection description={description} />
        <div className="col-md-6 login-wrap-bg">
          <div className="login-wrapper">
            <div className="loginbox">
              <LogoAndBackSection />
              <div className="w-100"> {children}</div>{" "}
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
};
