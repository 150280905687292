import React from "react";
import { Link } from "react-router-dom";
import ROUTES from "../../../helpers/constants/routes";

const AddNewCourseTopography = () => {
  return (
    <div className="row align-items-center">
      <div className="col-md-12">
        <div className="add-course-header">
          <h2>Add New Course</h2>
          <div className="add-course-btns">
            <ul className="nav">
              <li>
                <Link to={ROUTES.DASHBOARD} className="btn btn-black">
                  Back to Dashboard
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCourseTopography;
