import React, { useEffect } from "react";
import CourseDetailWrapper from "./Wrapper";
import CourseDetailsComp from "../../../components/students/CourseDetails";
import PropTypes from "prop-types";

const CourseDetail = ({setHideSideBar}) => {
  useEffect(()=>{
    setHideSideBar(true);
  },[]);

  return (
    <CourseDetailWrapper>
      <CourseDetailsComp />
    </CourseDetailWrapper>
  );
};

export default CourseDetail;
CourseDetail.propTypes = {
  setHideSideBar: PropTypes.func.isRequired,
};
